import {
  NavigationGuardNext,
  RouteLocationNormalized,
  createRouter,
  createWebHistory
} from 'vue-router'
import AdminPage from '@/pages/AdminPage.vue'
import ChatPage from '@/pages/ChatPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import { Modals, type VtoData } from '@/types/modal'
import {
  useAuthStore,
  useErrorStore,
  useModalControllerStore,
  useAdminStore,
  useRoutineStore
} from '@/store'
import { logError } from '@/utils/errorUtils'
import { informationPageContents } from '@/utils/onetrust'
import { userHasNotConsented } from '@/utils/user-utils'
import { RoutineAPI } from '@/api'
import { getUrlParam } from '@/utils/web'

const popstateListener = (e: PopStateEvent) => {
  const modalStore = useModalControllerStore()
  if (modalStore.runningModal !== Modals.NONE) {
    e.preventDefault()
    modalStore.back()
  } else {
    window.removeEventListener('popstate', popstateListener)
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/chat',
      name: 'chat',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.closeModal()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/scan',
      name: 'scan',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSelfie()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/skinResults',
      name: 'skinResults',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSkinDiag()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/faceAnalysis',
      name: 'faceAnalysis',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setFaceAnalysis()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/skinInfo',
      name: 'skinInfo',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setSkinInfo()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/auth',
      name: 'auth',
      component: LoginPage,
      meta: {
        public: true
      },
      beforeEnter: (to, from, next) => {
        const errorStore = useErrorStore()
        const adminStore = useAdminStore()

        errorStore.showErrorPage = false
        const envMaintenance = import.meta.env.VITE_UNDER_MAINTENANCE ?? 'false'
        if (to.name !== 'admin' && adminStore.underMaintenance && envMaintenance === 'true') {
          errorStore.errorMessage = '✨ Beauty Genius is Under Maintenance'
          errorStore.showErrorPage = true
        }
        window.addEventListener('popstate', popstateListener)
        next()
      }
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          const authStore = useAuthStore()
          const path = getUrlParam('path') ?? 'chat'
          const args = getUrlParam('args') ?? '{}'
          let allowSkip = false
          if (authStore.isAnonymous) {
            allowSkip = true
          }
          modalStore.setLogin(path, args, allowSkip)
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/deleteAccount',
      name: 'deleteAccount',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setDeleteAccount()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/profile',
      name: 'profile',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          modalStore.setProfile()
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/termsConds',
      name: 'termsConds',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          const authStore = useAuthStore()
          if (!userHasNotConsented(authStore)) {
            next('/chat')
          } else {
            modalStore.reload(window.localStorage.getItem('modalHistory'))
            modalStore.setTermsConds()
            next()
          }
        })
      },
      component: ChatPage
    },
    {
      path: '/information',
      name: 'information',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          if (
            to.query.page &&
            !Array.isArray(to.query.page) &&
            to.query.page in informationPageContents()
          ) {
            modalStore.setInformationPage(to.query.page)
          }
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/PDP',
      name: 'PDP',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          let upc = ''
          if (to.query.upc) {
            upc = to.query.upc as string
          }

          modalStore.setProduct({ upc, msgIndex: -1, sendEvent: true })
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/vto',
      name: 'vto',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, () => {
          const modalStore = useModalControllerStore()
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          let upcs: string[] = ['']
          if (to.query.upcs) {
            upcs = (to.query.upcs as string).split(',')
          }
          const data = {
            upcs: upcs,
            selected: upcs[0]
          } as VtoData
          modalStore.setVTO(data)
          next()
        })
      },
      component: ChatPage
    },
    {
      path: '/routine',
      name: 'routine',
      beforeEnter: (to, from, next) => {
        window.addEventListener('popstate', popstateListener)
        guard(to, from, next, async () => {
          const modalStore = useModalControllerStore()
          const routineStore = useRoutineStore()
          let validRoutineId = true
          modalStore.reload(window.localStorage.getItem('modalHistory'))
          if (to.query.ref) {
            const routineId = to.query.ref as string
            let routine = routineStore.routines[routineId]
            if (!routine) {
              routine = await RoutineAPI.getRoutine(routineId)
            }
            if (routine) {
              routineStore.updateCache(routineId, routine)
            } else {
              validRoutineId = false
              next('/chat')
            }
            if (routineStore.checkCurrentRoutineValid() && validRoutineId) {
              routineStore.loadedFromUrl = true
              routineStore.setRoutine()
              next()
            }
          } else {
            /*
              if we can figure out how to keep the urls from being 
              over written we can load the last routine without 
              explicitly giving the id
            */
            next('/chat')
          }
        })
      },
      component: ChatPage
    },
    {
      path: '/admin',
      name: 'admin',
      beforeEnter: (to, from, next) => {
        guard(to, from, next, () => {
          next()
        })
      },
      component: AdminPage
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'chat' }
    }
  ]
})

const guard = (
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext,
  callback: () => void | Promise<void>
) => {
  const authStore = useAuthStore()
  const errorStore = useErrorStore()
  const adminStore = useAdminStore()

  errorStore.showErrorPage = false
  const envMaintenance = import.meta.env.VITE_UNDER_MAINTENANCE ?? 'false'
  if (to.name !== 'admin' && adminStore.underMaintenance && envMaintenance === 'true') {
    errorStore.errorMessage = '✨ Beauty Genius is Under Maintenance'
    errorStore.showErrorPage = true
  }

  authStore
    .authRoute(Boolean(to.meta.public))
    .then((validUser) => {
      if (validUser) {
        if (userHasNotConsented(authStore)) {
          next()
        } else {
          callback()
        }
      } else if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'false') {
        window.location.href = '/auth'
      } else {
        errorStore.errorMessage = 'Failed to login'
        errorStore.showErrorPage = true
      }
    })
    .catch((e) => {
      logError(e)
      errorStore.errorMessage = 'Failed to login'
      errorStore.showErrorPage = true
    })
}

router.beforeEach(() => {
  window.dataLayer = window.dataLayer || []
  const authStore = useAuthStore()
  authStore.setParentURL()
})

router.afterEach((to: RouteLocationNormalized) => {
  if (['login', 'admin', 'auth'].includes(to.name as string)) return
  const authStore = useAuthStore()
  const modalStore = useModalControllerStore()
  if (authStore.isAnonymous) {
    modalStore.setLogin(to.name as string, JSON.stringify(to.query), true)
  }
})
