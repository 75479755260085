<template>
  <div
    v-if="showSuggestedResponses"
    class="max-w-[90%] px-6"
  >
    <button
      v-for="(suggestedResponse, index) in computedSuggestedResponses"
      :key="index"
      color="transparent"
      :class="[
        'rounded-2xl mx-1 mt-1 p-3 border border-[#bebebe]',
        suggestedResponse.title ? 'custom-full-width' : ''
      ]"
      @click="async () => await selectResponse(suggestedResponse)"
    >
      <div class="flex flex-col gap-1">
        <div class="w-full text-black text-sm text-left font-semibold capitalize">
          {{ suggestedResponse.title }}
        </div>
        <div class="flex flex-row justify-between items-end">
          <div class="w-full text-black text-sm text-left">
            {{ suggestedResponse.details ?? suggestedResponse.response }}
          </div>

          <div class="ml-1 w-[20px]">
            <SvgIcon
              color="black"
              name="arrow-send"
            />
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { SuggestedResponse } from '@/types/chats'

interface ISuggestedResponsesProps {
  suggestedResponses: SuggestedResponse[]
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { tracking } from '@/tracking/EventController'
import { useChatStore } from '@/store'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

const chatStore = useChatStore()

const props = defineProps<ISuggestedResponsesProps>()

const showSuggestedResponses = computed(() => {
  return props.suggestedResponses.some((response) => response.details ?? response.response)
})

const computedSuggestedResponses = computed(() => {
  return props.suggestedResponses.filter((response) => response.details ?? response.response)
})

const emit = defineEmits(['select'])

const selectResponse = async (response: SuggestedResponse) => {
  tracking.selectFollowupMessageEvent(chatStore.currentSessionId, response.response)
  // Emit an event with the selected response
  emit('select', response.response)
}
</script>

<style scoped>
.custom-full-width {
  width: 100%;
}
</style>
