import type { User } from 'firebase/auth'

export type Auth = {
  user: User | null
  oldUser: User | null
  error: string | null
  loading: boolean
  isAnonymous: boolean
  consented: boolean
  parentURL: string | null
}

export const isFirebaseError = (error: any): error is { code: string; message: string } => {
  return error.code && error.message
}
