<template>
  <div
    :class="[
      open ? '' : 'pointer-events-none',
      'z-30 h-full w-full absolute left-0 top-0 overflow-hidden flex flex-col items-center'
    ]"
  >
    <Modal
      :open="isCurrent(Modals.PROFILE)"
      @back="onBack"
      button-color="light"
    >
      <ProfileModal @back="onBack" />
    </Modal>

    <Modal
      :open="isCurrent(Modals.EDIT_NAME)"
      @back="onBack"
      button-color="dark"
    >
      <EditNameModal @back="onBack" />
    </Modal>

    <Modal
      :open="isCurrent(Modals.PRODUCT)"
      button-color="dark"
      button-background-color="#ffffffB3"
      @back="onBack"
    >
      <ProductCardModal
        :upc="productCard?.upc"
        :msg-index="productCard?.msgIndex"
        :prod-index="productCard?.productIndex ?? 0"
        :send-event="productCard?.sendEvent ?? false"
        v-if="isPreviousOrCurrent(Modals.PRODUCT)"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.ROUTINE)"
      button-color="dark"
      @back="onBack"
    >
      <RoutineModal
        v-if="isPreviousOrCurrent(Modals.ROUTINE)"
        :routine="routineData?.routine"
        :msg-index="routineData?.msgIndex"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.SKINDIAG)"
      button-color="dark"
      buttonBackgroundColor="rgba(255, 255, 255, 0.7)"
      @back="onBack"
    >
      <SkinDiagModal />
    </Modal>

    <Modal
      :open="isCurrent(Modals.FACE_ANALYSIS)"
      button-color="dark"
      buttonBackgroundColor="rgba(255, 255, 255, 0.7)"
      @back="onBack"
    >
      <FaceAnalysis />
    </Modal>

    <Modal
      :open="isCurrent(Modals.TERMS_CONDS)"
      :back-button="false"
      type="drawer"
    >
      <TermsConds />
    </Modal>

    <Modal
      :open="isCurrent(Modals.INFORMATION_PAGE)"
      button-color="dark"
      button-background-color="#ffffffB3"
      @back="onBack"
    >
      <InformationPage
        :id="modalStore.informationPage.id"
        :content="modalStore.informationPage.content"
      />
    </Modal>

    <Modal
      :open="isCurrent(Modals.INFORMATION_PAGES)"
      @back="onBack"
      button-color="dark"
    >
      <InformationPages />
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import Modal from '@/components/layout/Modal.vue'
import ProductCardModal from '@/pages/ProductCardModal.vue'
import SkinDiagModal from '@/pages/SkinDiagModal.vue'
import RoutineModal from '@/pages/RoutineModal.vue'
import { useModalControllerStore } from '@/store/modalStore'
import { Modals } from '@/types/modal'
import ProfileModal from './ProfileModal.vue'
import TermsConds from './TermsConds.vue'
import InformationPage from './InformationPage.vue'
import EditNameModal from '@/pages/EditNameModal.vue'
import InformationPages from '@/pages/InformationPages.vue'
import FaceAnalysis from './FaceAnalysis.vue'

const modalStore = useModalControllerStore()

const running = computed(() => modalStore.runningModal)
const productCard = computed(() => modalStore.productCard)
const routineData = computed(() => modalStore.routine)
const open = computed(() => modalStore.runningModal !== Modals.NONE)

const onBack = () => {
  modalStore.back()
}

const previousModal = () => modalStore.previous()
const isPrevious = (modal: Modals) => modal === previousModal()
const isCurrent = (modal: Modals) => modal === running.value
const isPreviousOrCurrent = (modal: Modals) => isPrevious(modal) || isCurrent(modal)
</script>
