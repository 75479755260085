<template>
  <div
    v-if="product"
    class="w-full p-6 flex flex-col justify-center items-center bg-white rounded-md"
    @click="onViewProductClicked"
  >
    <div class="w-full flex flex-row gap-2 items-center">
      <div
        class="w-[16px] h-[16px] flex justify-center items-center rounded-full bg-[#F5F0EA] font-semibold text-[10px]"
      >
        {{ index + 1 }}
      </div>
      <div class="font-semibold font-[Campton] text-[20px]">
        {{ step.title }}
      </div>
    </div>
    <div class="w-full p-4 flex flex-col gap-6">
      <div class="flex flex-row gap-1 font-semibold">
        <div class="w-[40%] flex flex-col justify-center px-6 object-cover">
          <div class="h-[64px] flex-shrink-0 relative">
            <img
              v-if="product.packshotImageUrl"
              class="w-full max-h-full object-contain"
              :src="product.packshotImageUrl"
              :alt="'Product packshot for ' + step.title"
            />
          </div>
        </div>
        <div class="w-full flex flex-col gap-1 text-black">
          <div
            v-if="product.franchise"
            class="text-[10px] overflow-ellipsis"
          >
            {{ spacing(product.franchise) }}
          </div>
          <div
            v-if="step.product"
            class="text-[10px] overflow-ellipsis"
          >
            {{ step.product }}
          </div>
          <Ratings
            :rating="product.rating"
            :rating-count="product.rating_Count"
          />
        </div>
      </div>
      <div
        v-if="shortDescription"
        class="text-[12px]"
        v-html="shortDescription"
      />
      <div
        v-if="instructionOfUse"
        class="text-[12px]"
        v-html="instructionOfUse"
      />
      <button
        class="text-[14px] font-normal underline text-left"
        @click="onViewProductClicked"
      >
        view product
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { GenAIRoutineStep } from '@/types/routine'

interface IRoutineCardStepProps {
  step: GenAIRoutineStep
  index: number
  msgIndex: number
}
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductStore, useModalControllerStore } from '@/store'
import Ratings from '@/components/molecules/Ratings.vue'
import DOMPurify from 'dompurify'
import { spacing } from '@/utils/string'

const productStore = useProductStore()
const modalStore = useModalControllerStore()

const props = defineProps<IRoutineCardStepProps>()

const product = computed(() =>
  props.step.upc
    ? productStore.getProduct(props.step.upc)
    : productStore.fetchProductByName(props.step.product)
)

const onViewProductClicked = () => {
  if (!product.value?.idGenAI) return
  modalStore.setProduct({ upc: product.value.idGenAI, msgIndex: props.msgIndex, sendEvent: true })
}
const shortDescription = computed(() =>
  !product.value?.shortDescription ? null : DOMPurify.sanitize(product.value.shortDescription)
)
const instructionOfUse = computed(() =>
  !product.value?.instructionOfUse ? null : DOMPurify.sanitize(product.value.instructionOfUse)
)
</script>
