import type { DiagRange, Gender } from '@/types/diag'

declare global {
  interface Window {
    gtag?: (...args: any[]) => void
  }
}

export enum EventTypes {
  PAGE = 'page',
  IDENTIFY = 'identify',
  TRACK = 'track'
}

export interface CommonFields {
  // Defines if the user is anonymous or not
  isAnonymous: boolean

  // App related fields
  appVersion: string

  // Page related fields
  pagePath: string
  pageTitle: string
  pageUrl: string

  // Screen related fields
  screenWidth: number
  screenHeight: number
  screenDensityF: number

  // Event related fields
  timezone: string
  timestamp: number
  userId: string
  version: number
}

export interface PageCommonFields {
  event: EventTypes.PAGE
  type: EventTypes.PAGE
}

export interface PageProperties {
  category?: string
  name?: string
}

export interface PageEvent extends CommonFields, PageCommonFields, PageProperties {}

export interface TrackingEvent {
  eventType: string
  eventData: object
}

export interface IdentifyCommonFields {
  event: EventTypes.IDENTIFY
}

export enum IdentifyTypes {
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset_password',
  SESSION_START = 'session_start'
}

export interface IdentifyProperties {
  type: IdentifyTypes
}

export interface IdentifyEvent extends CommonFields, IdentifyCommonFields, IdentifyProperties {}

export interface TrackCommonFields {
  event: EventTypes.TRACK
}

export enum TrackTypes {
  SEND_MESSAGE = 'send_message',
  LAUNCHED_MESSAGE_PROMPT = 'launched_message_prompt',
  FACESCAN_RESULTS = 'facescan_results',
  SELFIE_SENT = 'selfie_sent',
  PRODUCT_RECOMMENDED = 'recommended_product',
  PRODUCT_RECOMMENDATION_MADE = 'product_recommendation_made',
  LAUNCH_VTO = 'launch_vto',
  CLICK_RECOMMENDED_RESPONSE = 'click_recommended_response'
}

export interface TrackProperties extends DiagTrackProperties {
  type: TrackTypes
  recommendedProductCount?: number
  category?: string
  topic?: string
  question_id?: string
  productUPC?: string
  productCategory?: string
  clickedRecommendedResponseCategory?: string
  clickedRecommendedResponseContent?: string
}

export interface DiagTrackProperties {
  //face diag
  faceMessage?: string
  faceId?: string
  darkCircles?: string
  eyeBrowColor?: string
  eyeBrowShape?: string
  eyeBrowsThickness?: string
  eyeColor?: string
  eyeCrease?: string
  eyeLashesLength?: string
  eyeShape?: string
  cupidVisibility?: string
  faceShape?: string
  foreheadSizes?: string
  lipsDescription?: string
  mouthWidth?: string
  noseDescription?: string
  hairColor?: string
  hairLength?: string
  hairState?: string
  skinTone?: string
  skinUnderTone?: string

  //skin diag
  skinMessage?: string
  blotchinessRange?: DiagRange
  blotchinessScore?: number
  darkPigmentationRange?: DiagRange
  darkPigmentationScore?: number
  deepWrinklesRange?: DiagRange
  deepWrinklesScore?: number
  finelinesRange?: DiagRange
  finelinesScore?: number
  globalWrinklesRange?: DiagRange
  globalWrinklesScore?: number
  lackOfFirmnessRange?: DiagRange
  lackOfFirmnessScore?: number
  lackOfRadianceRange?: DiagRange
  lackOfRadianceScore?: number
  largePoresRange?: DiagRange
  largePoresScore?: number
  wrinklesRange?: DiagRange
  wrinklesScore?: number
  imageConcernsGoodLighting?: boolean
  imageConcernsFacingForward?: boolean
  imageConcernsLightingQuality?: string
  skinId?: string
  gender?: Gender
}

export interface TrackEvent extends CommonFields, TrackCommonFields, TrackProperties {}

export interface GTMService {
  service_category: string
  service_name: string
  service_version: string
}

export type eventProduct = {
  id?: string
  brand: string
  category: string
  position: number
  price: string
  name: string
  variant: string
  list: string
}

export type dimensions = {
  dimension91: string
}

export type Ecommerce = {
  currencyCode: string
  impressions: eventProduct[]
} & dimensions

export interface GTMEvent extends GTMService {
  event: string
  event_name: string
  ecommerce: Ecommerce | string
  eventCategory: string
  eventAction: string
  eventLabel: string
  cta_name?: string
  scroll_percentage?: string
  service_step?: string
  event_detail?: string
  feature_name?: string
  product_info?: string
  click?: string
  actionField?: string
  products?: eventProduct & dimensions
}
