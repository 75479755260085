<template>
  <div
    :class="[
      open ? 'modal-shadow translate-x-0' : 'translate-y-[100%] modal-shadow-hidden',
      'rounded-t-2xl top-4 ',
      'w-full h-full max-w-mobile',
      'modal-transition',
      'absolute bottom-0',
      'overflow-hidden pb-4'
    ]"
    ref="overlay"
  >
    <div
      v-if="visible"
      :class="[
        'bottom-4',
        'absolute top-0 right-0 left-0 overflow-hidden w-full bg-black modal-transition'
      ]"
    >
      <CloseButton
        v-if="closeButton"
        data-testid="modal-close"
        :color="buttonColor"
        class="absolute top-5 right-5"
        @click="onCloseClicked"
      />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
interface IOverlayProps {
  open: boolean
  buttonColor?: 'light' | 'dark'
  buttonBackgroundColor?: string
  closeButton?: boolean
}
</script>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { ANIMATION_END_EVENT } from '@/constants'
import CloseButton from '@/components/atoms/CloseButton.vue'

const transitioning = ref(false)
const overlay = ref<HTMLDivElement | null>(null)
const recentlyClosed = ref(false)

const openComplete = () => {
  overlay.value?.scrollIntoView({ behavior: 'smooth' })
}

const emit = defineEmits(['close'])

const onCloseClicked = () => {
  recentlyClosed.value = true
  emit('close')
}

const props = withDefaults(defineProps<IOverlayProps>(), {
  open: false,
  buttonColor: 'light',
  closeButton: true
})

const visible = computed(() => props.open || transitioning.value)

const onTransitionEnd = () => {
  transitioning.value = false
}
const onTransitionStart = () => {
  if (props.open) {
    transitioning.value = true
  } else if (recentlyClosed.value) {
    recentlyClosed.value = false
    transitioning.value = true
  }
}

onMounted(() => {
  recentlyClosed.value = false
  window.addEventListener(ANIMATION_END_EVENT, openComplete)
  if (overlay.value) {
    overlay.value.addEventListener('transitionend', onTransitionEnd)
    overlay.value.addEventListener('transitionstart', onTransitionStart)
  }
})

onUnmounted(() => {
  window.removeEventListener(ANIMATION_END_EVENT, openComplete)
  if (overlay.value) {
    overlay.value.removeEventListener('transitionend', onTransitionEnd)
    overlay.value.removeEventListener('transitionstart', onTransitionStart)
  }
})
</script>

<style lang="scss" scoped>
.modal-shadow-hidden {
  box-shadow: 0 0px 0px #00000000;
}

.modal-shadow {
  box-shadow: 0 0px 15px #000000a0;
}

.modal-transition {
  transition: all var(--slide-animation) var(--transition-duration-med-slow);
}
</style>
