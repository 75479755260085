<template>
  <div class="flex flex-row justify-between items-center h-10">
    <div class="overflow-hidden text-ellipsis text-[#484848] text-[14px] leading-5 font-normal">
      {{ label }}
    </div>
    <div class="overflow-hidden text-ellipsis text-[#161616] text-[14px] leading-5 font-semibold">
      {{ formattedValue }}
    </div>
  </div>
</template>

<script lang="ts">
interface IFeatureItemProps {
  label: string
  value: string
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<IFeatureItemProps>()

const formattedValue = computed(() => {
  if (props.value) return props.value.charAt(0).toUpperCase() + props.value.slice(1)
  else return ''
})
</script>
