<template>
  <ScrollBottomContainer
    class="h-full pb-44 pt-16 w-full absolute max-w-mobile z-10"
    @scroll="onScroll"
  >
    <ChatMessages :messages="historyMessages" />
    <ChatMessageSeparator :content="chatStore.currentSessionSeparator" />
    <WelcomeHeader />
    <WelcomeCards @question-click="onWelcomeCardQuestionClicked" />
    <ChatMessages :messages="currentMessages" />
    <SuggestedResponses
      v-if="chatStore.showSuggestedResponses"
      :suggested-responses="chatStore.suggestedResponses"
      @select="(prompt) => sendPrompt(prompt)"
    />
  </ScrollBottomContainer>
</template>

<script lang="ts">
import { ref, onMounted, UnwrapRef, watch } from 'vue'
import ChatInput from '@/components/chat/ChatInput.vue'
import { tracking } from '@/tracking/EventController'
import { useChatStore } from '@/store'

interface IChatPanelProps {
  chatInput: UnwrapRef<typeof ChatInput>
}
</script>

<script setup lang="ts">
import ScrollBottomContainer from '@/components/layout/ScrollBottomContainer.vue'
import ChatMessages from '@/components/chat/ChatMessages.vue'
import ChatMessageSeparator from '@/components/atoms/ChatMessageSeparator.vue'
import WelcomeHeader from '@/components/molecules/WelcomeHeader.vue'
import WelcomeCards from '@/components/chat/WelcomeCards.vue'
import SuggestedResponses from '@/components/chat/SuggestedResponses.vue'
import { ChatMessage } from '@/types/chats'

const props = defineProps<IChatPanelProps>()

const chatStore = useChatStore()

const historyMessages = ref<ChatMessage[]>([])
const currentMessages = ref<ChatMessage[]>([])

const sendPrompt = (prompt: string) => props?.chatInput.sendPrompt(prompt)
const focusTextArea = () => props?.chatInput.focusTextArea()

const emit = defineEmits(['scroll'])

const onScroll = (scrollTop: number) => {
  emit('scroll', scrollTop)
}

const onWelcomeCardQuestionClicked = (prompt: string) => {
  tracking.selectOpeningMessageEvent(useChatStore().currentSessionId, prompt)
  sendPrompt(prompt)
}

defineExpose({ focusTextArea, sendPrompt })

const setHistory = () => {
  historyMessages.value = chatStore.chatHistory.slice(0, chatStore.sessionIndex)
  currentMessages.value = chatStore.chatHistory.slice(chatStore.sessionIndex)
}

watch(
  () => chatStore.chatHistory,
  () => {
    setHistory()
  }
)

onMounted(() => {
  setHistory()
})
</script>
