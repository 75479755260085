import { defineStore } from 'pinia'
import _ from 'lodash'
import { ChatAPI, CRMAPI } from '@/api'
import { useProductStore } from './productStore'
import type { ParsedEvent, ReconnectInterval } from 'eventsource-parser'
import {
  appendAIMessage,
  appendMessageContent,
  appendUserImageMessage,
  appendUserTextMessage,
  getFormattedTimestamp,
  newChat,
  showError,
  updateMessage,
  updateMessageId
} from '@/utils/chat-store-utils'
import type { FeedbackOverall } from '@/types/feedback'
import type {
  Chat,
  ChatHistoryResponse,
  ChatMessage,
  ChatMessageResponse,
  ChatMessageType
} from '@/types/chats'
import { useFlagStore, useAuthStore } from '@/store'
import { SESSION_IDENTIFIER } from '@/constants'
import { logError, logMessage } from '@/utils/errorUtils'
import DOMPurify from 'dompurify'
import { stringEmpty } from '@/utils/string'
import { isAIMessage, getChatMessageFromHistoryMessage } from '@/utils/messages-utils'
import { tracking } from '@/tracking/EventController'
import { type eventProduct } from '@/types/tracking'
import { getProductName } from '@/utils/product'
import { ProcessedImage, SkinDiag } from '@/types/products'
import { getAuth } from '@/api/client'
import { SuggestedResponse } from '@/types/chats'
import tutorialContents from '@/data/tutorialContents.json'

export const useChatStore = defineStore('chats', {
  state: () => ({
    ready: false,
    currentSessionId: '',
    chats: {} as Record<string, Chat>,
    chatOrder: [] as string[],
    feedback: null as { messageId?: string; overall: FeedbackOverall; message?: string } | null,
    inputMessage: '',
    messageId: '',
    skinDiag: null as SkinDiag | null,
    suggestedResponsesFlag: false,
    suggestedResponsesCategory: 'N/A',
    suggestedResponses: [] as (SuggestedResponse & ProcessedImage)[],
    currentSessionSeparator: '',
    typing: false
  }),

  getters: {
    textEntryDisabled(state): boolean {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return false
      const currentChatMessages = _.last(state.chats[lastSessionId].messages)
      return (
        state.typing ||
        !state.ready ||
        (_.size(currentChatMessages) > 0 && !currentChatMessages?.complete)
      )
    },
    currentChat(state): Chat | undefined {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return undefined
      return state.chats[lastSessionId]
    },
    currentChatMessages(state): ChatMessage[] {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return []
      return state.chats[lastSessionId].messages
    },
    currentMessage(state): ChatMessage | undefined {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return undefined
      const currentChatMessages = state.chats[lastSessionId].messages
      return _.last(currentChatMessages)
    },
    currentMessageId(state): string | undefined {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return undefined
      const currentChatMessages = state.chats[lastSessionId].messages
      return _.last(currentChatMessages)?.id
    },
    chatHistory(state): ChatMessage[] {
      return state.chatOrder.map((sessionId) => state.chats[sessionId].messages).flat()
    },
    sessionIndex(state): number {
      const lastSessionId = _.last(state.chatOrder)
      if (!lastSessionId) return -1
      const currentChatMessageCount = state.chats[lastSessionId].messages.length
      const totalMessageCount = state.chatOrder
        .map((sessionId) => state.chats[sessionId].messages.length)
        .reduce((a, b) => a + b, 0)
      return totalMessageCount - currentChatMessageCount
    },
    showSuggestedResponses(state): boolean {
      return !state.typing && state.suggestedResponsesFlag && state.suggestedResponses.length > 0
    }
  },
  actions: {
    setReady() {
      this.ready = true
    },
    setInputMessage(prompt: string) {
      this.inputMessage = prompt
    },
    setFeedback(newVal: { messageId: string; overall: FeedbackOverall; message: string } | null) {
      this.feedback = newVal
    },
    showUserImageAfterSelfie(messageId: string): boolean {
      const lastSessionId = _.last(this.chatOrder)
      if (!lastSessionId) return false
      const messages = this.chats[lastSessionId].messages
      // fetch from -2 which is the second last item
      const secondLast = messages.at(-2)
      if (!secondLast || messages.at(-1)?.id !== messageId) return false
      // fetch from -3 which is the third last item
      const thirdLast = messages.at(-3)
      if (!thirdLast) return false
      return secondLast.type === 'image' && !thirdLast.skinDiag
    },
    startNewSession(newSessionId: string) {
      this.chats[newSessionId] = newChat()
      this.chatOrder.push(newSessionId)
      this.switchSessionId(newSessionId)
      tracking.serviceOpeningEvent()
    },
    switchSessionId(sessionId: string) {
      this.currentSessionId = sessionId
    },
    isCurrentChatMessage(messageId: string) {
      return this.currentMessageId === messageId
    },
    setSkinDiag(skinDiag: SkinDiag | undefined | false) {
      if (!skinDiag) return
      this.skinDiag = skinDiag
    },
    setSuggestedResponsesFlag(flag: boolean) {
      if (!flag) this.suggestedResponses = []
      this.suggestedResponsesFlag = flag
    },
    setSuggestedResponses(responses: string | ProcessedImage[], category: string) {
      this.suggestedResponsesFlag = false
      if (!responses || !category) {
        this.suggestedResponsesCategory = 'N/A'
        this.suggestedResponses.length = 0
        return
      }
      this.suggestedResponsesCategory = category

      const formattedResponses =
        typeof responses === 'string' ? (JSON.parse(responses) as SuggestedResponse[]) : responses
      if (this.suggestedResponses.length === 0) {
        this.suggestedResponses = formattedResponses
        this.suggestedResponsesFlag = typeof responses === 'string'
      } else {
        this.suggestedResponses = _.map(formattedResponses, this.matchImageToReponse)
      }
    },
    matchImageToReponse(response: SuggestedResponse | ProcessedImage) {
      const foundMatch = _.find(
        this.suggestedResponses,
        (obj) =>
          (response.caption ?? 'caption').toLowerCase() === (obj.title ?? 'title').toLowerCase() ||
          (response.title ?? 'title').toLowerCase() === (obj.caption ?? 'caption').toLowerCase()
      )
      if (!foundMatch) this.suggestedResponsesFlag = true
      return { ...foundMatch, ...response }
    },
    async streamResponse({
      type,
      content,
      imageId,
      onParse,
      onEnd
    }: {
      type: ChatMessageType
      content: string | null
      imageId?: string
      onParse: (arg0: any) => void
      onEnd: () => void
    }): Promise<void> {
      const authStore = useAuthStore()
      if (!(await authStore.checkToken())) return
      const auth = getAuth()

      tracking.sendMessageEvent(this.currentSessionId, content)
      ChatAPI.postMessageStreaming({
        onParse,
        onEnd,
        type,
        message: content,
        sessionId: this.currentSessionId,
        imageId,
        auth
      })
    },
    /**
     * Append both User and AI messages.
     */
    appendMessages(content: string | null, imageId: string, tempId: string) {
      const chat = this.currentChat as Chat
      if (imageId) {
        // image input
        appendUserImageMessage({ chat, message: { imageId } })
      } else {
        if (!content) return
        // add user text message to history and clear input
        appendUserTextMessage({ chat, message: { content } })
        this.inputMessage = ''
      }
      // add initial AI Message (placeholder)
      appendAIMessage({ chat, message: { id: tempId } })
      this.messageId = tempId
    },
    async postMessage({ prompt, imageId }: { prompt?: string; imageId?: string }) {
      if (!this.currentChat) return

      const type: ChatMessageType = imageId ? 'image' : 'text'

      const input = prompt ?? this.inputMessage
      const content: string | null = stringEmpty(input) ? null : DOMPurify.sanitize(input)
      if (type === 'text' && !content) {
        logMessage('Empty message after sanitizing user input', 'warning')
        return
      }
      const tempId = 'awaiting-id'
      this.appendMessages(content, imageId ?? '', tempId)

      try {
        await this.streamResponse({
          type,
          content,
          imageId,
          onParse: this.onParse,
          onEnd: this.onEnd
        })
      } catch (e) {
        showError({
          chat: this.currentChat,
          data: {},
          messageId: 'error',
          error: 'Something happened :(\nWe were unable to process your request. Try again soon.'
        })
        logError(e)
      }
    },
    async getHistory() {
      const allHistory: ChatHistoryResponse[] = []
      try {
        // First fetch before pushing for better error handling
        const history = await ChatAPI.getAllHistory()
        allHistory.push(...history)
      } catch (e) {
        logError(e)
      }
      for (const [i, history] of allHistory.entries()) {
        const {
          chat: { messages, ...chat },
          products: { productData }
        } = history

        let hydratedMessages = messages.map((message) => getChatMessageFromHistoryMessage(message))

        if (i === 0) {
          if (_.size(productData)) {
            const productStore = useProductStore()
            productStore.pushProducts(productData)
            productStore.fetchingReviews = true
            productStore.fetchReviews(_.keys(productData))
          }
        } else {
          hydratedMessages = hydratedMessages.map(
            (hydratedMessages) => _.omit(hydratedMessages, ['productUpcs']) as ChatMessage
          )
        }

        //add time stamps
        hydratedMessages[0].sessionIdentifier = getFormattedTimestamp(
          history.chat.createdAt.toString()
        )
        if (i === 0)
          this.currentSessionSeparator = `${getFormattedTimestamp()} | ${SESSION_IDENTIFIER}`
        this.chats[chat.id] = { ...chat, messages: hydratedMessages }
        this.chatOrder[allHistory.length - i - 1] = chat.id
      }
    },
    getProductsFromMessage(msgIndex: number) {
      if (msgIndex && this.chatHistory) {
        const history = this.chatHistory[msgIndex]
        if (history && isAIMessage(history) && history.productUpcs) {
          return history.productUpcs
        }
      }
      return []
    },
    /**
     * Event source parser
     */
    async onParse(event: ParsedEvent | ReconnectInterval) {
      const chat = this.currentChat
      if (!chat) return

      try {
        // Wait for news
        if (event.type === 'reconnect-interval') {
          return
        }

        // Fetch everything we need
        const {
          id: messageId,
          content = '',
          sessionId,
          skinDiag,
          products = {},
          images,
          routineRecommendationId,
          routine,
          suggestedResponses,
          disclaimer,
          category
        }: ChatMessageResponse = JSON.parse(event.data)

        if (!messageId) {
          return
        }

        const crmConsent = useFlagStore().getFlag('consentCRM')
        if (category && category !== 'GENERAL' && crmConsent) {
          const name = (useAuthStore().user?.displayName ?? '').split(' ')
          const firstName = name[0] ?? ''
          const lastName = name[1] ?? ''
          CRMAPI.beautyExperience({
            firstName,
            lastName,
            sessionID: this.currentSessionId,
            categories: [category]
          })
        }

        // Set the Current chat
        if (sessionId) {
          this.switchSessionId(sessionId)
        }

        // Updating MessageId
        if (messageId) {
          updateMessageId({ chat, previousId: this.messageId, newId: messageId })
          this.messageId = messageId
        }

        // Update images
        if (_.size(images)) {
          this.setSuggestedResponses(
            _.flatMap(images, (image) =>
              tutorialContents[image] !== undefined ? tutorialContents[image].images : []
            ),
            'suggestedResponses.category'
          )
        }

        // Update routine
        if (routine) {
          updateMessage({
            chat,
            messageId,
            message: { routineRecommendationId, routine }
          })
        }

        if (disclaimer) {
          updateMessage({
            chat,
            messageId,
            message: { disclaimer }
          })
        }

        if (suggestedResponses) {
          this.setSuggestedResponsesFlag(true)
          this.setSuggestedResponses(suggestedResponses.content, suggestedResponses.category)
        }

        // Something bad happened. Fail early
        if (event.event === 'error') {
          showError({
            chat,
            data: event.data,
            messageId,
            error: 'Something happened :(\nWe were unable to process your request. Try again soon.'
          })
          throw new Error(event.data)
        }

        this.setSkinDiag(skinDiag)

        const { productUpcs, productData = {} } = products

        // Update with metadata
        updateMessage({
          chat,
          messageId,
          message: _.omitBy(
            {
              skinDiag,
              productUpcs
            },
            _.isUndefined
          )
        })

        // Synchronous Method
        if (_.size(productData)) {
          const trackedProducts: eventProduct[] = Object.keys(productData).map((key, index) => ({
            brand: productData[key].brandCode ?? '',
            variant: productData[key].franchise,
            name: getProductName(productData[key]) ?? '',
            position: index,
            category: productData[key].productType ?? '',
            price: productData[key].eCommerce?.price ?? '',
            list: 'list-result-range'
          }))
          tracking.productRecommendationEvent(this.currentSessionId)
          tracking.productListEvent(trackedProducts)

          const productStore = useProductStore()
          productStore.pushProducts(productData)
          productStore.fetchingReviews = true
          productStore.fetchReviews(productUpcs ?? [])
        }

        if (content !== '') {
          appendMessageContent({ chat, messageId, content })
        }

        // chunks.push(message)
      } catch (e) {
        updateMessage({
          chat,
          messageId: this.messageId,
          message: { complete: true }
        })
        logError(e)
      }
    },
    /**
     * Last message
     */
    onEnd() {
      tracking.replyReceivedEvent(this.currentSessionId)
      const chat = this.currentChat
      if (!chat) return
      updateMessage({
        chat,
        messageId: this.messageId,
        message: { complete: true }
      })
    }
  }
})
