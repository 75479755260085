export type FilteredSkinDiag = {
  [key: string]: {
    score?: number
    [key: string]: DiagRange | number | boolean | undefined
  }
}

export type ScoreSignPare = {
  score: number
  sign: string
}

export type FaceDiagResult = {
  message?: string
  id: string
  darkCircles: string
  eyeBrowColor: string
  eyeBrowShape: string
  eyeBrowsThickness: string
  eyeColor: string
  eyeCrease: string
  eyeLashesLength: string
  eyeShape: string
  cupidVisibility: string
  faceShape: string
  foreheadSizes: string
  lipsDescription: string
  mouthWidth: string
  noseDescription: string
  hairColor: string
  hairLength: string
  hairState: string
  skinTone: string
  skinUnderTone: string
}

export enum Gender {
  M = 'm',
  F = 'f'
}

export enum DiagRange {
  NA = 'n/a',
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export type SkinDiagResult = {
  message?: string
  blotchiness: {
    range: DiagRange
    score: number
  }
  darkPigmentation: {
    range: DiagRange
    score: number
  }
  deepWrinkles: {
    range: DiagRange
    score: number
  }
  finelines: {
    range: DiagRange
    score: number
  }
  globalWrinkles: {
    range: DiagRange
    score: number
  }
  lackOfFirmness: {
    range: DiagRange
    score: number
  }
  lackOfRadiance: {
    range: DiagRange
    score: number
  }
  largePores: {
    range: DiagRange
    score: number
  }
  wrinkles: {
    range: DiagRange
    score: number
  }
  imageConcerns: {
    goodLighting: boolean
    facingForward: boolean
    lightingQuality: string
  }
  id: string
  gender: Gender
  skinType?: string
}

export type DiagResult = {
  faceShape: FaceDiagResult | null
  skinDiag: SkinDiagResult | null
}

export type DiagDeleteResult = {
  faceShape: string
  skinDiag: string
}

export const diagTextMapping = {
  faceShape: {
    eyeColor: 'Eye Color',
    eyeBrowColor: 'Eyebrow Color',
    eyeLashesLength: 'Eyelashe Length',
    eyeShape: 'Eye Shape',
    faceShape: 'Face Shape',
    hairColor: 'Hair Color',
    hairLength: 'Hair Length',
    hairState: 'Hair State',
    skinTone: 'Skin Tone',
    skinUnderTone: 'Skin Undertone'
  },
  faceShapeResults: {
    verydeep: 'very deep',
    notfreckled: 'not freckled',
    fewfreckled: 'few freckles',
    moderatelyfreckled: 'moderately freckled',
    veryfreckled: 'very freckled',
    roundeyes: 'round eyes',
    almondeyes: 'almond eyes',
    monolideyes: 'monolid eyes',
    neutralcreasedeyes: 'neutral creased eyes',
    hoodedeyes: 'hooded eyes',
    downturnedeyes: 'downturned eyes',
    neutraleyes: 'neutral eyes',
    upturnedeyes: 'upturned eyes',
    closeseteyes: 'close set eyes',
    wideseteyes: 'wide set eyes',
    protrudingeyes: 'protruding eyes',
    deepseteyes: 'deep set eyes'
  },
  skinDiag: {
    blotchiness: {
      name: 'Blotchiness',
      description:
        'This is skin that`s uneven, with some areas appearing lighter or darker than others.',
      image: '/images/blotchiness.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    darkPigmentation: {
      name: 'Dark Pigmentation',
      description:
        'These are dark spots or patches on your skin, often caused by sun damage or uneven melanin production',
      image: '/images/dark-pigmentation.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    deepWrinkles: {
      name: 'Deep Wrinkles',
      description:
        'These are noticeable lines or creases on your face that don`t disappear when you relax your muscles.',
      image: '/images/deep-wrinkles.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    finelines: {
      name: 'Fine Lines',
      description: 'These are thin lines on your face, often the first signs of aging.',
      image: '/images/fine-lines.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    globalWrinkles: {
      name: 'Global Wrinkles',
      description: 'Global Wrinkles',
      image: '/images/global-wrinkles.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    lackOfFirmness: {
      name: 'Loss of Firmness',
      description: 'This means your skin feels less bouncy and supple than it used to.',
      image: '/images/lack-of-firmness.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    lackOfRadiance: {
      name: 'Dullness',
      description: 'This makes your skin appear dull and lifeless.',
      image: '/images/lack-of-radiance.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    largePores: {
      name: 'Enlarged Pores',
      description: 'These are the tiny openings in your skin that appear wider than usual.',
      image: '/images/large-pores.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    wrinkles: {
      name: 'Wrinkles',
      description:
        'These are general lines or creases on your face, including both deep wrinkles and fine lines.',
      image: '/images/wrinkles.png',
      levels: ['None', 'Minimal', 'Moderate', 'Significant']
    },
    imageConcerns: {
      name: 'Image Concerns',
      description: '',
      image: '',
      levels: []
    },
    goodLighting: {
      name: 'Good Lighting',
      description: '',
      image: '',
      levels: []
    },
    facingForward: {
      name: 'Facing Forward',
      description: '',
      image: '',
      levels: []
    },
    lightingQuality: {
      name: 'Lighting Quality',
      description: '',
      image: '',
      levels: []
    }
  }
}

// Beauty state
export type BeautyState = SkinState & HairState & EyeState

type SkinConcern =
  | 'fine lines'
  | 'wrinkles'
  | 'firmness'
  | 'pores'
  | 'radiance'
  | 'dark spots'
  | null

export type FaceState = {
  enhancer_finish: 'matte' | 'satin' | 'natural' | 'other'
  lipstick_texture: 'classic lipstick' | 'liquid' | 'gloss' | 'balm'
  lipstick_color: 'red' | 'pink' | 'brown' | 'purple' | 'other'
}

export type SkinState = {
  skin_type: 'normal' | 'dry' | 'oily' | 'combination' | 'sensitive' | null
  skin_tone: 'fair' | 'light' | 'medium' | 'tan' | 'dark' | 'deep' | null
  skin_undertone: 'cool' | 'warm' | 'neutral' | null
  skin_concerns: SkinConcern[]
  current_routine_length: 'short' | 'long' | null
}

export type HairState = {
  hair_type: 'straight' | 'wavy' | 'curly' | 'coily' | null
  current_hair_color: 'black' | 'brown' | 'blonde' | 'red' | 'gray' | 'white' | 'dyed' | null
  natural_hair_color: 'black' | 'brown' | 'blonde' | 'red' | 'gray' | 'white' | null
  hair_color_goals: string | null
}

export type EyeState = {
  eye_color: 'blue' | 'brown' | 'green' | 'hazel' | 'amber' | 'gray' | 'black' | null
  eye_shape: 'round' | 'almond' | 'monolid' | 'hooded' | 'upturned' | 'downturned' | null
}
