import { defineStore } from 'pinia'
import { GenAIRoutine } from '@/types/routine'
import { useModalControllerStore } from './modalStore'

export const useRoutineStore = defineStore('routines', {
  state: () => ({
    routines: {} as Record<string, GenAIRoutine>,
    currentRoutineId: '' as string,
    currentRoutine: {} as GenAIRoutine,
    loadedFromUrl: false as boolean
  }),
  actions: {
    setRoutine() {
      const modalStore = useModalControllerStore()
      modalStore.setRoutine(this.currentRoutineId, { routine: this.currentRoutine, msgIndex: -1 })
    },
    resetLoadedFromUrl() {
      if (this.loadedFromUrl) {
        this.loadedFromUrl = false
      }
    },
    checkCurrentRoutineValid() {
      return this.currentRoutineId !== undefined && this.currentRoutine !== undefined
    },
    addToCache(routineId: string, routine: GenAIRoutine) {
      if (this.routines[routineId] !== routine) {
        this.routines[routineId] = routine
      }
    },
    updateCurrentRoutine(routineId: string, routine: GenAIRoutine) {
      this.currentRoutineId = routineId
      this.currentRoutine = routine
    },
    updateCache(routineId: string, routine: GenAIRoutine) {
      if (this.checkCurrentRoutineValid()) {
        this.addToCache(this.currentRoutineId, this.currentRoutine)
      }
      this.updateCurrentRoutine(routineId, routine)
    }
  },
  persist: {
    storage: sessionStorage
  }
})
