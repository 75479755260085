<template>
  <button
    v-if="routine"
    data-testid="routine-to-detail"
    class="relative w-full px-6"
    @click="openDetailedRoutineCard"
  >
    <div class="w-[90%] h-[400px] relative">
      <img
        class="w-full h-full object-cover rounded-xl"
        src="/images/skin-routine-button-bg.png"
        alt="skin-routine-card"
      />
      <div
        class="w-full h-full absolute left-0 top-0 bg-gradient-to-b from-[#12121255] to-[#121212ee] rounded-xl"
      />
      <div class="w-full h-full absolute left-0 top-0">
        <div class="p-[16px] h-full w-full text-left text-[#FFF]">
          <div class="flex flex-col h-full w-full justify-between">
            <SvgIcon name="day-and-night" />
            <div class="flex flex-row w-full justify-between items-start">
              <div class="flex flex-col gap-1">
                <div class="text-[20px] leading-[28px] pr-6 font-[Campton] font-semibold">
                  An effective skincare routine for you
                </div>
                <div class="pr-6">
                  <div
                    class="bg-[#EEEEEE] rounded-[4px] leading-[28px] font-[Campton] font-semibold text-[#161616] px-2 inline-block"
                  >
                    {{ routineComplexity }}
                  </div>
                </div>
              </div>
              <div class="relative m-1">
                <SvgIcon
                  name="button-click-arrow-white"
                  class="absolute right-0 top-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import { GenAIRoutine } from '@/types/routine'

interface IChatRoutineCardProps {
  routineRecommendationId: string | undefined
  routine: GenAIRoutine
  msgIndex: number
  productUpcs: string[]
}
</script>

<script setup lang="ts">
import { useModalControllerStore } from '@/store/modalStore'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { computed, onBeforeMount, onMounted } from 'vue'
import { useProductStore, useRoutineStore } from '@/store'

const modalControllerStore = useModalControllerStore()
const routineStore = useRoutineStore()
const productStore = useProductStore()
const props = defineProps<IChatRoutineCardProps>()

onMounted(() => {
  const routineIdCheck =
    props.routineRecommendationId !== undefined &&
    props.routineRecommendationId !== routineStore.currentRoutineId
  if (routineStore.loadedFromUrl && routineIdCheck) {
    routineStore.addToCache(props.routineRecommendationId, props.routine)
  } else if (!routineStore.loadedFromUrl && routineIdCheck) {
    routineStore.updateCache(props.routineRecommendationId, props.routine)
  }
})

onBeforeMount(async () => {
  productStore.hydrateProducts(props.productUpcs, props.routine)
})

const routineComplexity = computed(() => {
  if (props.routine.morning.length > 3) {
    return 'Comprehensive'
  }
  return 'Simple'
})

const openDetailedRoutineCard = () => {
  if (props.routineRecommendationId && props.routine && props.msgIndex) {
    routineStore.updateCache(props.routineRecommendationId, props.routine)
    modalControllerStore.setRoutine(props.routineRecommendationId, {
      routine: props.routine,
      msgIndex: props.msgIndex
    })
  }
}
</script>
