import { defineStore } from 'pinia'
import { ChatAPI } from '@/api'

export type PopularQuestion = { category: string; prompt: string }

export const usePopularQuestionStore = defineStore('popularQuestion', {
  state: () =>
    ({
      questions: []
    }) as { questions: PopularQuestion[] },
  actions: {
    async fetchQuestions() {
      this.questions = await ChatAPI.getQuestions()
    },
    setQuestions(questions: PopularQuestion[]) {
      this.questions = questions
    }
  }
})
