import { defineStore } from 'pinia'
import type { ProductData } from '@/types/products'
import { useChatStore } from '@/store/chatStore'
import { ProductAPI } from '@/api'
import _, { isEmpty } from 'lodash'
import { fuzzy } from 'fast-fuzzy'
import { getProductName } from '@/utils/product'
import { GenAIRoutine, GenAIRoutineStep } from '@/types/routine'

export const useProductStore = defineStore('products', {
  state: () => ({
    // maps upc to product
    products: {} as Record<string, ProductData>,
    // maps name to product (for routine, as upcs are not part of the reco)
    nameToProduct: {} as Record<string, ProductData>,
    fetchingReviews: false
  }),

  actions: {
    getProduct(upc: string): ProductData {
      return this.products[upc]
    },
    // retrieve product cards fetched from both beauty hub and cms
    async getProducts(upcs: string[]): Promise<ProductData[]> {
      const productUpcs = [...upcs]
      let products = _.compact(
        productUpcs.map((upc, index) => {
          const product = this.getProduct(upc)
          if (product) productUpcs[index] = ''
          return product
        })
      )
      if (productUpcs.length != products.length)
        products = _.concat(products, await this.fetchProducts(_.compact(productUpcs)))

      return products
    },

    checkProducts(upcs: string[]): string[] {
      return upcs.filter((upc) => {
        const product = this.products[upc]
        return !!product?.shadeArray
      })
    },

    hasVTO(upc?: string | null): boolean {
      if (!upc) return false
      const product = this.getProduct(upc)
      return !_.isEmpty(product.shadeArray) && product.metier != 'Foundation'
    },

    pushProduct({
      upc,
      product,
      messageId
    }: {
      upc: string
      product: ProductData
      messageId: string | undefined
    }) {
      product.messageId = messageId
      this.products[upc] = { ...this.products[upc], ..._.omit(product, 'reviews') } //todo: remove _.omit when ganAI removes reviews
    },

    pushProducts(products: Record<string, ProductData>) {
      const messageId = useChatStore().currentMessageId
      _.forEach(products, (product, upc) => {
        this.pushProduct({ upc, product, messageId })
      })
    },

    /**
     * Append a new product to the name-product mappings.
     */
    pushProductByName(productName: string, product: ProductData) {
      this.nameToProduct[productName] = product
    },

    /**
     * Fetch a save product from the name-product mappings.
     */
    fetchProductByName(productName: string): ProductData | null {
      return this.nameToProduct[productName]
    },

    async fetchProducts(upcs: string[]) {
      const products = await ProductAPI.getProducts(upcs)
      if (products) {
        this.pushProducts(products)
        return _.values(products)
      }
      return []
    },

    async fetchReviews(upcs: string[]) {
      const reviews = await ProductAPI.reviews(upcs.map((product) => this.products[product].upc))
      if (reviews) {
        upcs.forEach((upc) => (this.products[upc].reviews = reviews[this.products[upc].upc]))
      }
      this.fetchingReviews = false
    },

    async hydrateProducts(upcs: string[], routine: GenAIRoutine) {
      const products = (await this.getProducts(upcs)).filter(
        (product: ProductData) => !isEmpty(product) && product.isFound
      )
      const steps = [...routine.morning, ...routine.evening]
      steps.forEach((step: GenAIRoutineStep) => {
        const matches = products.filter((product: ProductData) => {
          const res = fuzzy(getProductName(product), step.product, {
            ignoreCase: true,
            ignoreSymbols: true
          })
          return res > 0.5
        })
        if (!isEmpty(matches)) this.pushProductByName(step.product, matches[0])
      })
    }
  }
})
