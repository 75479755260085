import { clients } from './client'
import { useAuthStore } from '@/store'
import { userHasNotConsented } from '@/utils/user-utils'

export default {
  getRoutine: async (hash: string) => {
    const authStore = useAuthStore()
    if (userHasNotConsented(authStore)) return []
    if (!(await authStore.checkToken())) return []
    const resp = await clients.bff.get('skinRoutine/saved-routine', {
      params: { id: hash }
    })
    return resp.data
  }
}
