<template>
  <div
    ref="selfieContainer"
    data-testid="selfie-root"
    class="w-full h-full relative"
  >
    <div class="w-full h-full flex justify-center">
      <canvas
        ref="selfieCanvas"
        data-testid="selfie-canvas"
      />
    </div>
    <Loading v-if="isLoading" />
    <div
      class="w-full absolute bottom-0 py-4 justify-center items-center text-center bg-custom-tw-selfie-ui-bg bg-opacity-70"
    >
      <div
        v-if="state === State.RETAKE"
        class="pb-4 text-white text-base font-medium drop-shadow-md"
      >
        The selfie could not be processed
      </div>
      <div class="flex flex-row gap-7 justify-center">
        <Button
          class="rounded-full text-sm py-3 px-8 shadow-sm"
          v-if="state === State.SHOOTING"
          @click="takeSelfie"
          data-testid="selfie-button-1"
        >
          Take a selfie
        </Button>
        <Button
          class="rounded-full text-sm py-3 px-8 shadow-sm"
          v-if="state === State.CONFIRMING || state === State.RETAKE"
          @click="retakeSelfie"
          data-testid="selfie-button-2"
          :color="'dark'"
        >
          Retake selfie
        </Button>
        <Button
          class="rounded-full text-sm py-3 px-8 shadow-sm"
          v-if="state === State.CONFIRMING"
          @click="submitSelfie"
          data-testid="selfie-button-3"
        >
          Submit selfie
        </Button>
      </div>
      <div
        v-if="state === State.SUBMITTING"
        class="w-full flex flex-col gap-3 justify-center items-center text-center cursor-default"
      >
        <div class="text-white text-base font-medium drop-shadow-md">Analyzing your selfie</div>
        <div class="w-full h-2 bg-gray-50 rounded-3xl">
          <div
            class="h-full rounded-3xl bg-custom-tw-selfie-progress-bar-border"
            :style="{ width: progressBarPercentage }"
          ></div>
        </div>
      </div>
      <div
        v-if="state === State.DONE"
        class="w-full flex flex-col gap-3 justify-center items-center text-center cursor-default"
      >
        <div
          class="px-8 py-1 flex flex-row justify-center items-center text-center rounded-full bg-emerald-600"
        >
          <div class="pt-1 uppercase text-white text-[0.8rem]">&check; Analyzed! Thank you</div>
        </div>
        <Button
          @click="endSelfieFlow()"
          data-testid="selfie-button-4"
        >
          Done
        </Button>
      </div>
    </div>
  </div>
  <img
    ref="selfieImage"
    class="hidden"
    alt="hidden-selfie"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import Loading from '@/components/atoms/Loading.vue'
import { useSelfieStore } from '@/store/selfieStore'
import Button from '@/components/atoms/Button.vue'
import { useVisionStore } from '@/store/visionStore'
import { useChatStore } from '@/store/chatStore'
import { logError } from '@/utils/errorUtils'
import { tracking } from '@/tracking/EventController'

/* store */
const selfieStore = useSelfieStore()
const visionStore = useVisionStore()
const chatStore = useChatStore()

const emit = defineEmits(['close'])

/* current state of the modal */
enum State {
  'LOADING',
  'SETTING_UP',
  'SHOOTING',
  'CONFIRMING',
  'SUBMITTING',
  'DONE',
  'EXITED',
  'RETAKE'
}
const state = ref<State>(State.LOADING)
/* function to properly reset after the modal is done to prevent leaks */
const cameraCleanupFunc = ref<(() => void) | null>(null)
const progressBarValue = ref(0)
const currentSelfieId = ref<string | null>(null)
const progressBarPercentage = computed(() => `${progressBarValue.value}%`)

/* computed */
const isLoading = computed(() => {
  if (state.value === State.LOADING) return true
  return state.value === State.SETTING_UP
})

const selfieContainer = ref<HTMLDivElement | null>(null)
const selfieCanvas = ref<HTMLCanvasElement | null>(null)
const selfieImage = ref<HTMLImageElement | null>(null)

/* lifecycle hooks */
onMounted(async () => {
  try {
    initContainer()
    await initCamera()
  } catch (e) {
    logError(e)
    closeSelfie()
  }
})

onBeforeUnmount(() => cleanUpModal())

// init the container width and height
const initContainer = () => {
  state.value = State.SETTING_UP
  if (!selfieContainer.value) {
    throw new Error('selfie-container not found')
  }
}

const renderCanvas = (
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement
) => {
  if (video.readyState === video.HAVE_ENOUGH_DATA) {
    if (state.value === State.SETTING_UP) {
      state.value = State.SHOOTING
    }
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    if (state.value === State.SHOOTING) {
      ctx.scale(-1, 1)
      ctx.drawImage(video, 0, 0, -canvas.width, canvas.height)
    }
    const stillStates = [State.CONFIRMING, State.SUBMITTING, State.DONE, State.EXITED, State.RETAKE]
    if (stillStates.some((s) => state.value === s) && image.complete) {
      ctx.scale(1, 1)
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    }
  }
}

const animateProgressBar = () => {
  if (state.value === State.SUBMITTING) {
    progressBarValue.value = Math.min(progressBarValue.value + 1, 100)
  }
}

const initCamera = async () => {
  const video = document.createElement('video')
  video.setAttribute('autoplay', '')
  video.setAttribute('muted', '')
  video.setAttribute('playsinline', '')
  const constraints = {
    audio: false,
    video: {
      facingMode: 'user',
      width: { ideal: 900 },
      height: { ideal: 900 }
    }
  }
  video.srcObject = await navigator.mediaDevices.getUserMedia(constraints)
  video.onloadedmetadata = () => {
    video.play()
  }
  const renderInterval = setInterval(() => {
    try {
      if (!selfieCanvas.value) {
        throw new Error('selfie-canvas not found')
      }
      const ctx = selfieCanvas.value.getContext('2d', { alpha: false })
      if (!ctx) {
        throw new Error('selfie-canvas context not found')
      }
      if (!selfieImage.value) {
        throw new Error('selfie-image not found')
      }
      renderCanvas(video, selfieCanvas.value, ctx, selfieImage.value)
      animateProgressBar()
    } catch (e) {
      logError(e)
      closeSelfie()
    }
  }, 1000 / 30)

  // function to cleanup all camera components
  cameraCleanupFunc.value = () => {
    video.pause()
    if (video.srcObject) {
      ;(video.srcObject as MediaStream).getTracks().forEach((track) => {
        track.stop()
      })
    }
    video.srcObject = null
    clearInterval(renderInterval)
  }
}

// captures the selfie and pushes it to the selfie store
const takeSelfie = async () => {
  try {
    if (!selfieCanvas.value) {
      throw new Error('selfie-canvas not found')
    }
    if (!selfieImage.value) {
      throw new Error('selfie-image not found')
    }
    state.value = State.CONFIRMING
    const dataURL = selfieCanvas.value.toDataURL('image/png')
    selfieImage.value.src = dataURL
  } catch (e) {
    logError(e)
    closeSelfie()
  }
}

const retakeSelfie = () => {
  state.value = State.SHOOTING
}

const submitSelfie = async () => {
  try {
    state.value = State.SUBMITTING

    progressBarValue.value = 0
    if (selfieImage.value?.src) {
      currentSelfieId.value = selfieStore.pushSelfie(selfieImage.value?.src)
      tracking.selfieSentEvent(chatStore.currentSessionId)
      const result = await selfieStore.submitSelfie(currentSelfieId.value)
      if (result) {
        tracking.faceScanResultEvent(chatStore.currentSessionId)
        visionStore.setDiag(result)
        state.value = State.DONE
        return
      }
    }
    state.value = State.RETAKE
  } catch (e) {
    logError(e)
    state.value = State.RETAKE
  }
}

const endSelfieFlow = () => {
  if (currentSelfieId.value) {
    chatStore.postMessage({ imageId: currentSelfieId.value })
  }
  closeSelfie()
}

// close the modal
const closeSelfie = () => {
  cleanUpModal()
  emit('close')
}

const cleanUpModal = () => {
  state.value = State.EXITED
  cameraCleanupFunc.value?.()
  cameraCleanupFunc.value = null
}
</script>
