<template>
  <div
    :class="[
      open ? '' : 'pointer-events-none',
      'z-30 h-full w-full absolute left-0 top-0 overflow-hidden flex flex-col items-center'
    ]"
  >
    <Overlay
      :open="isCurrent(Overlays.VTO)"
      @close="onClose"
    >
      <VTOModal
        :upcs="vto?.upcs"
        :selected="vto?.selected"
        :msg-index="vto?.msgIndex"
        v-if="isPreviousOrCurrent(Overlays.VTO)"
      />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.PRE_SELFIE)"
      @close="onCloseSkin"
      button-color="dark"
    >
      <PreSelfieModal
        :type="modalStore.SelfieType"
        v-if="isPreviousOrCurrent(Overlays.PRE_SELFIE)"
      />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.SKIN_INFO)"
      @close="onClose"
      button-color="dark"
    >
      <SkinInfoModal
        :type="modalStore.SelfieType"
        v-if="isPreviousOrCurrent(Overlays.SKIN_INFO)"
      />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.SELFIE)"
      @close="onCloseSkin"
      button-color="dark"
    >
      <SelfieModal
        v-if="isPreviousOrCurrent(Overlays.SELFIE)"
        @close="onCloseSkin"
      />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.FEEDBACK)"
      button-color="dark"
      @close="onClose"
    >
      <FeedbackModal
        v-if="isPreviousOrCurrent(Overlays.FEEDBACK)"
        @close="onClose"
      />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.LOGIN)"
      button-color="dark"
      :close-button="!modalStore.canSkipLogin"
      button-background-color="#ffffffB3"
      @close="onClose"
    >
      <LoginModal :skip="modalStore.canSkipLogin" />
    </Overlay>

    <Overlay
      :open="isCurrent(Overlays.DELETE_ACCOUNT)"
      button-color="dark"
      button-background-color="#ffffffB3"
      @close="onClose"
    >
      <DeleteAccountModal />
    </Overlay>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Overlay from '@/components/layout/Overlay.vue'
import VTOModal from '@/pages/VTOModal.vue'
import SelfieModal from '@/pages/SelfieModal.vue'
import FeedbackModal from '@/pages/FeedbackModal.vue'
import { useModalControllerStore, useVisionStore } from '@/store'
import { Overlays, Modals } from '@/types/modal'
import PreSelfieModal from '@/pages/PreSelfieModal.vue'
import LoginModal from './LoginModal.vue'
import DeleteAccountModal from '@/pages/DeleteAccountModal.vue'
import SkinInfoModal from './SkinInfoModal.vue'

const modalStore = useModalControllerStore()
const visionStore = useVisionStore()

const running = computed(() => modalStore.runningOverlay)
const vto = computed(() => modalStore.vto)
const open = computed(() => modalStore.runningOverlay !== Overlays.NONE)

const onCloseSkin = () => {
  if (
    (modalStore.runningModal === Modals.SKINDIAG ||
      modalStore.runningModal === Modals.FACE_ANALYSIS) &&
    !visionStore.diagComplete
  )
    modalStore.back()
  onClose()
}

const onClose = () => {
  modalStore.closeOverlay()
}

const previousModal = () => modalStore.previous()
const isPrevious = (overlay: Overlays) => overlay === previousModal()
const isCurrent = (overlay: Overlays) => overlay === running.value
const isPreviousOrCurrent = (overlay: Overlays) => isPrevious(overlay) || isCurrent(overlay)
</script>
