<template>
  <div
    class="flex flex-col gap-[8px] w-full h-full relative bg-custom-tw-background-sign-in"
    data-testid="terms-conds"
  >
    <!-- top section -->
    <div class="relative px-4">
      <div
        id="termsCondsTopBar"
        class="pt-3 flex flex-row gap-[32px]"
        :class="customTermsCondsTopBarStyle"
      >
        <BackButton
          v-show="modalStore.termsCondsIndex > 0"
          color="dark"
          background="#FFFFFFB3"
          class="shadow-[0_1px_8px_0px_rgba(25,23,23,0.04)] backdrop-blur-[10px]"
          @click="onPage2BackButtonClick"
        />
        <!-- progress bar -->
        <div class="w-full h-[40px] ml-6 mr-14 flex flex-col justify-center">
          <div class="bg-[#EEEEEE] w-full h-1 rounded-[4px]">
            <div
              ref="completionBarRef"
              class="custom-completion-bar bg-black h-full rounded-[4px] transition-all"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="h-full w-full flex flex-col overflow-y-auto gap-[8px]">
      <!-- img -->
      <div class="flex flex-row justify-center items-center flex-shrink-0">
        <img
          class="h-[156px] object-cover"
          src="/images/R.png"
          alt="VBC logo"
        />
      </div>
      <div
        v-if="modalStore.termsCondsIndex === 0"
        class="h-full w-full flex flex-col justify-start px-4 gap-[32px]"
      >
        <div class="flex flex-col gap-[8px]">
          <div class="text-[#161616] font-[Campton] font-semibold text-[28px] leading-[36px]">
            Welcome to Beauty Genius Beta
          </div>
          <div class="text-[#484848] text-[14px] leading-[20px]">
            Thanks for testing Beauty Genius, our AI beauty assistant. Here's what to know:
          </div>
        </div>
        <ul class="list-disc ml-[20px] space-y-4 [&>li]:pl-2">
          <li>
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                We're an AI Assistant
              </div>
              <div class="text-[#484848] text-[14px] leading-[20px]">
                You're talking with an AI, not a real person.
              </div>
            </div>
          </li>
          <li>
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                Your Feedback Matters
              </div>
              <div class="text-[#484848] text-[14px] leading-[20px]">
                Responses can be inaccurate, so your feedback is invaluable.
              </div>
            </div>
          </li>
          <li>
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                Beauty Tips Only
              </div>
              <div class="text-[#484848] text-[14px] leading-[20px]">
                This is not a medical device or an alternative to a doctor.
              </div>
            </div>
          </li>
          <li>
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                Conversations Are Recorded
              </div>
              <div class="text-[#484848] text-[14px] leading-[20px]">
                We record conversations to improve the app, sharing insights with L'Oréal and
                trusted partners.
              </div>
            </div>
          </li>
          <li>
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                Information to Avoid Sharing
              </div>
              <div class="text-[#484848] text-[14px] leading-[20px]">
                Avoid sharing any sensitive information such as credit card numbers or specific
                health conditions or diagnoses.
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- page 2 -->
      <div
        v-else-if="modalStore.termsCondsIndex === 1"
        class="h-full w-full flex flex-col justify-start px-4 gap-[32px]"
      >
        <div class="flex flex-col gap-[8px]">
          <div class="text-[#161616] font-[Campton] font-semibold text-[28px] leading-[36px]">
            Before we get started
          </div>
          <div class="text-[#484848] text-[14px] leading-[20px]">
            To access Beauty Genius, we also require your agreement to Our
            <a
              class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
              @click="modalStore.setInformationPage('termsOfUse')"
            >
              Terms of Use
            </a>
            . These terms (among other things) provide us with the right to use any content and
            information you submit to Beauty Genius to improve our products and services and
            includes an arbitration provision to resolve disputes.
            <br />
            <br />
            Beauty Genius uses the information you provide (including a selfie, if you choose) to
            personalize your experience. For more information, please see our
            <a
              class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
              @click="modalStore.setInformationPage('privacyNotice')"
            >
              Privacy Notice
            </a>
            .
            <br />
            <br />
            Beauty Genius offers a optional feature that lets you analyze your skin and virtually
            try-on products using a selfie. You're not required to use this feature at all.
            <br />
            <br />
            To access Beauty Genius, we require confirmation that you understand how we handle
            selfie data, even if you choose not to use the feature right now.
          </div>
        </div>
        <div class="flex flex-col gap-[8px]">
          <div class="text-[#161616] font-[Campton] font-semibold text-[20px] leading-[28px]">
            Please confirm the following:
          </div>
          <div class="text-[#161616] font-semibold text-[16px] leading-[28px]">Mandatory</div>
          <div>
            <ul class="space-y-4">
              <li class="flex flex-row">
                <DesignedCheckbox
                  data-testid="terms-conds-checkbox1"
                  v-model="checkbox1"
                />
                <div class="flex flex-col gap-[4px]">
                  <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                    Terms of Use
                  </div>
                  <div class="text-[#484848] text-[12px] leading-[18px]">
                    I confirm I'm a US resident, and I've reviewed the
                    <a
                      class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                      @click="modalStore.setInformationPage('termsOfUse')"
                    >
                      Terms of Use
                    </a>
                    and agree to all terms.
                  </div>
                </div>
              </li>
              <li class="flex flex-row">
                <DesignedCheckbox
                  data-testid="terms-conds-checkbox2"
                  v-model="checkbox2"
                />
                <div class="flex flex-col gap-[4px]">
                  <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                    Biometric Notice
                  </div>
                  <div class="text-[#484848] text-[12px] leading-[18px]">
                    I consent to having my face scanned and image processed as described in the
                    <a
                      class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                      @click="modalStore.setInformationPage('informationNotice')"
                    >
                      Biometric Notice
                    </a>
                    and agree to all its terms, including as regards data retention, data deletion,
                    and data use, processing, storage, and transfer.
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="text-[#161616] font-semibold text-[16px] leading-[28px]">Optional</div>
          <div class="flex flex-row">
            <DesignedCheckbox
              data-testid="terms-conds-checkbox3"
              v-model="checkbox3"
            />
            <div class="flex flex-col gap-[4px]">
              <div class="text-[#161616] font-semibold text-[14px] leading-[20px]">
                Marketing Communications
              </div>
              <div class="text-[#484848] text-[12px] leading-[18px]">
                You need to be at least 18+ and a US resident to receive our communications. You may
                unsubscribe to promotional emails through the link in each communication we send.
                <br />
                You agree L'Oréal Paris (or a third party on its behalf) will collect and use any
                information You provide during Your use of Beauty Genius, results of the skin and
                face analysis, analysis reports provided to You, and any inferences and
                recommendations associated with your name and contact information (i.e., categories
                of interest), to provide you with tailored and personalized marketing and
                promotional communications (including direct communications, emails, and
                advertisements displayed on third-party sites and social media platforms) from
                L'Oréal Paris and other
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                  href="https://www.loreal.com/en/usa/articles/contact-us/consumer-inquiries-usa/"
                >
                  L'Oréal brands
                </a>
                . Information is stored within the beauty profile L'Oréal Paris maintains about You.
                <br />
                For more information about L'Oréal Paris privacy practices and how to exercise
                rights available you (where available, including the right to revoke this consent),
                please visit
                <a
                  class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                  @click="modalStore.setInformationPage('informationNotice')"
                >
                  Beauty Genius Information Notice
                </a>
                ,
                <a
                  class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                  @click="modalStore.setInformationPage('privacyNotice')"
                >
                  Privacy Policy
                </a>
                , and
                <a
                  class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
                  @click="modalStore.setInformationPage('PrivacyChoices')"
                >
                  Your Privacy Choices
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div
      v-if="modalStore.termsCondsIndex === 0"
      class="px-4 flex flex-col items-center"
    >
      <DesignedButton
        data-testid="terms-conds-next-pg1"
        @click="onPage1NextButtonClick"
        class="mt-[6px] mb-2"
      >
        NEXT
      </DesignedButton>
    </div>
    <div
      v-else-if="modalStore.termsCondsIndex === 1"
      class="px-4 flex flex-col items-center"
    >
      <DesignedButton
        data-testid="terms-conds-accept-pg2"
        class="mt-[6px] mb-2"
        @click="updateConsented"
        :disabled="!bothChecked"
      >
        GET STARTED
      </DesignedButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import BackButton from '@/components/atoms/BackButton.vue'
import DesignedCheckbox from '@/components/designedComponents/DesignedCheckbox.vue'
import DesignedButton from '@/components/designedComponents/DesignedButton.vue'
import {
  useModalControllerStore,
  useAuthStore,
  useRoutineStore,
  useChatStore,
  useFlagStore
} from '@/store'
import { FlagAPI, RoutineAPI, CRMAPI } from '@/api'
import { tracking } from '@/tracking/EventController'
import { type VtoData } from '@/types/modal'
import { informationPageContents } from '@/utils/onetrust'

const modalStore = useModalControllerStore()
const authStore = useAuthStore()
const routineStore = useRoutineStore()
const chatStore = useChatStore()
const flagStore = useFlagStore()

const checkbox1 = ref(false)
const checkbox2 = ref(false)
const checkbox3 = ref(false)

const completionBarRef = ref<HTMLDivElement | null>(null)

const customTermsCondsTopBarStyle = computed(() => {
  const style: string[] = []
  if (modalStore.termsCondsIndex === 0) {
    style.push('custom-padding-left')
  }
  return style
})

const setCompletionBar = (value: string) => {
  if (completionBarRef.value) {
    completionBarRef.value.style.setProperty('--completion-bar', value)
  }
}

const bothChecked = computed(() => checkbox1.value && checkbox2.value)

const onPage1NextButtonClick = () => {
  modalStore.termsCondsIndex = 1
  checkbox1.value = false
  checkbox2.value = false
  checkbox3.value = false
  setCompletionBar('100%')
}
const onPage2BackButtonClick = () => {
  modalStore.termsCondsIndex = 0
  setCompletionBar('50%')
}

const updateConsented = async () => {
  await FlagAPI.setFlag('consent', true)
  authStore.setConsented(true)
  tracking.privacyConsentEvent()
  if (checkbox3.value) {
    await FlagAPI.setFlag('consentCRM', true)
    flagStore.setFlag('consentCRM', true)
    const name = (authStore.user?.displayName ?? '').split(' ')
    const firstName = name[0] ?? ''
    const lastName = name[1] ?? ''
    await CRMAPI.beautyExperience({ firstName, lastName, sessionID: chatStore.currentSessionId })
  }
  modalStore.closeModal()
  const args = modalStore.afterConsentArgs
  switch (modalStore.goToAfterConsent) {
    case 'vto': {
      let upcs: string[] = ['']
      if (args.upcs) {
        upcs = (args.upcs as string).split(',')
      }
      const data = {
        upcs: upcs,
        selected: upcs[0]
      } as VtoData
      modalStore.setVTO(data)
      break
    }
    case 'PDP': {
      let upc = ''
      if (args.upc) {
        upc = args.upc as string
      }

      modalStore.setProduct({ upc, msgIndex: -1, sendEvent: true })
      modalStore.closeOverlay()
      break
    }
    case 'skinInfo': {
      modalStore.setSkinInfo()
      break
    }
    case 'scan': {
      modalStore.setSelfie()
      break
    }
    case 'skinResults': {
      modalStore.setSkinDiag()
      modalStore.closeOverlay()
      break
    }
    case 'routine': {
      let validRoutineId = true
      modalStore.reload(window.localStorage.getItem('modalHistory'))

      if (!args.ref) {
        modalStore.closeModal()
        break
      }
      const routineId = args.ref as string
      let routine = routineStore.routines[routineId]
      if (!routine) {
        routine = await RoutineAPI.getRoutine(routineId)
      }
      if (routine) {
        routineStore.updateCache(routineId, routine)
      } else {
        validRoutineId = false
        modalStore.closeModal()
      }
      if (routineStore.checkCurrentRoutineValid() && validRoutineId) {
        routineStore.loadedFromUrl = true
        routineStore.setRoutine()
      }
      modalStore.closeOverlay()
      break
    }
    case 'profile': {
      modalStore.setProfile()
      modalStore.closeOverlay()
      break
    }
    case 'deleteAccount': {
      modalStore.setDeleteAccount()
      break
    }
    case 'information': {
      if (args.page && !Array.isArray(args.page) && args.page in informationPageContents()) {
        modalStore.setInformationPage(args.page)
      }

      modalStore.closeOverlay()
      break
    }
    default: {
      modalStore.closeModal()
    }
  }
}

onMounted(() => {
  if (modalStore.termsCondsIndex === 1) {
    setCompletionBar('100%')
  }
})
</script>

<style scoped lang="scss">
.custom-padding-left {
  padding-left: 32px;
}

@property --completion-bar {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: false;
}

.list-disc li::marker {
  color: #000000;
  font-size: 1.25rem;
}

.custom-completion-bar {
  width: var(--completion-bar);
}
</style>
