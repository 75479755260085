<template>
  <div class="flex flex-col justify-between w-full h-full relative bg-custom-tw-background-sign-in">
    <div class="px-4 w-full h-full relative flex flex-col justify-between">
      <div class="w-full pt-[88px] px-11">
        <div class="flex flex-row justify-center items-center w-full h-[96px]">
          <div
            class="flex flex-col w-[96px] h-full bg-[#EEEEEE] rounded-full items-center justify-center"
          >
            <SvgIcon
              class="justify-center items-center"
              name="BeautyGeniusHandoverWarning"
            />
          </div>
        </div>
        <div class="pt-8">
          <div
            class="text-center font-[Campton] font-semibold text-[28px] text-[#161616] leading-[36px]"
          >
            Are you sure you want to delete your Beauty Genius account?
          </div>
          <div
            class="pt-2 text-center font-[Inter] font-normal text-[14px] text-[#484848] leading-[20px]"
          >
            We understand that sometimes things change, but deleting your account means you'll lose
            access to all your personalized features, including:
          </div>
        </div>
        <div class="flex flex-col justify-center pt-8 gap-4">
          <div class="flex flex-row gap-4 items-center">
            <div class="w-[30px] h-[30px] flex flex-row justify-center items-center rounded-full">
              <SvgIcon name="pre-scan-camera" />
            </div>
            <div class="w-full font-[Inter] text-[14px] font-semibold leading-[20px]">
              Skin analysis history and recommendations
            </div>
          </div>
          <div class="flex flex-row gap-4 items-center">
            <div class="w-[30px] h-[30px] flex flex-row justify-center items-center rounded-full">
              <SvgIcon name="pre-scan-glasses" />
            </div>
            <div class="w-full font-[Inter] text-[14px] font-semibold leading-[20px]">
              Product suggestions and routine guidance
            </div>
          </div>
        </div>
      </div>
      <DesignedButton
        class="mt-[6px] mb-2"
        @click="deleteAccount"
      >
        DELETE ACCOUNT
      </DesignedButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import DesignedButton from '@/components/designedComponents/DesignedButton.vue'
import { useUIStore, useAuthStore, useModalControllerStore } from '@/store'
import FirebaseAuth, { AuthAction } from '@/auth/firebase'
import { useRouter } from 'vue-router'
import { Dialog } from '@/types/dialog'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { getParentDomainAsUrlParam } from '@/utils/parentEvent'

const uiStore = useUIStore()
const authStore = useAuthStore()
const auth = new FirebaseAuth(authStore)
const router = useRouter()
const modalController = useModalControllerStore()

const signOut = async () => {
  await auth.signOut()
  modalController.closeModal()
  if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'true') {
    window.location.reload()
  } else {
    const additionalParams = getParentDomainAsUrlParam()
    await router.replace({ name: 'auth', ...additionalParams })
  }
}

const deleteAccount = () =>
  uiStore.displayDialog(
    // note that the following callback needs to be wrapped in a Closure to work properly,
    // otherwise the auth context will be unavailable, i.e. directly passing `auth.delete` won't work
    async () => {
      const action = await auth.delete()
      if (action === AuthAction.SIGN_OUT) {
        await signOut()
      }
    },
    {
      title: 'Caution',
      message:
        'Are you sure you want to delete your account? This will delete everything associated with your current account and this action cannot be undone.' +
        ' Since this is a sensitive operation, you may need to login again to confirm your identity.',
      confirmText: 'DELETE'
    } as Dialog
  )
</script>
