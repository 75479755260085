import { defineStore } from 'pinia'
import { ChatAPI } from '@/api'

export const useMissionStore = defineStore('mission', {
  state: () =>
    ({
      missions: {}
    }) as { missions: Record<string, string> },
  actions: {
    async fetchMissions() {
      this.missions = await ChatAPI.getMissions()
    },
    setMission(mission: Record<string, string>) {
      this.missions = mission
    }
  }
})
