<template>
  <div
    v-show="enabled"
    class="bg-white relative w-full max-w-[768px] p-2 z-40"
  >
    <div class="relative w-full flex flex-row gap-1">
      <button
        class="w-full h-full flex p-3 rounded-[200px] border border-black border-opacity-20 justify-center items-center capitalize font-bold text-sm"
        @click="modalStore.setVtoFromProduct()"
      >
        Try it on
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useModalControllerStore, useProductStore } from '@/store'
import _ from 'lodash'

const modalStore = useModalControllerStore()
const productStore = useProductStore()

const enabled = ref(false)

onMounted(async () => {
  if (!modalStore.productCard) return
  const upc = modalStore.productCard.upc
  const products = await productStore.getProducts([upc])
  if (products.length > 0) {
    const product = products[0]
    enabled.value = !_.isEmpty(product.shadeArray)
  }
})
</script>
