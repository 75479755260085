<template>
  <div
    class="px-4 flex flex-col relative justify-between w-full h-full bg-custom-tw-background-sign-in"
    data-testid="sign-in-page"
  >
    <div class="h-full flex flex-col justify-start overflow-y-auto">
      <div class="mt-8 flex flex-row justify-center items-center flex-shrink-0">
        <img
          class="h-[156px] object-cover"
          src="/images/R.png"
          alt="VBC-logo"
        />
      </div>
      <div class="mt-8 flex flex-col items-center justify-center text-center">
        <div class="text-[#161616] font-[Campton] font-semibold text-[28px] leading-[36px]">
          Sign-In
        </div>
        <div class="mt-2 mb-8 px-10 text-[#484848] font-normal text-sm">
          Sign-in to start using BeautyGenius, L'Oréal Paris' AI-powered beauty assistant.
        </div>
      </div>
    </div>
    <!-- slot for FirebaseUI -->
    <slot />
    <div
      class="h-48 mt-[16px] text-center w-full text-[#484848] font-medium text-[16px] leading-[28px]"
      v-if="canSkip"
      @click="skipLogin"
    >
      SKIP
    </div>
    <div class="mt-2 mb-2 text-[#484848] font-normal text-sm">
      By signing in you are agreeing to our
      <a
        class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
        @click="modalStore.setInformationPage('termsOfUse')"
      >
        Terms of Use
      </a>
      (which contains an arbitration provision to resolve disputes) and acknowledge our
      <!-- Priacy Policy right now points to privacy notice -->
      <a
        class="text-[#002A65] font-[Inter] font-[700] underline hover:no-underline hover:cursor-pointer"
        @click="modalStore.setInformationPage('privacyNotice')"
      >
        Privacy Policy
      </a>
      . When you use Google to login to our site, be advised your data is governed by Google’s
      privacy policies and terms of use.
    </div>
  </div>
</template>

<script lang="ts">
interface ILoginPageContentsProps {
  canSkip?: boolean
}
</script>

<script setup lang="ts">
import { useModalControllerStore } from '@/store'

const modalStore = useModalControllerStore()

withDefaults(defineProps<ILoginPageContentsProps>(), {
  canSkip: false
})

const emit = defineEmits(['skip'])

const skipLogin = () => {
  emit('skip')
}
</script>
