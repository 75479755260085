<template>
  <div class="relative w-full h-full flex justify-center page-offwhite pb-2">
    <div
      class="z-30 drop-shadow h-[40px] absolute top-5 right-5 flex flex-row gap-2 bg-[#FFFFFF] text-[#000000] w-[120px] justify-center items-center rounded-full font-[Inter] px-2 py-[12px] text-[14px] leading-[18px] font-semibold"
      @click="onReScanClick"
    >
      <SvgIcon
        name="rescan"
        color="black"
      />
      Rescan
    </div>
    <div class="h-full flex flex-col items-center gap-6 overflow-y-auto overflow-x-hidden">
      <img
        class="w-full object-cover object-top"
        :src="defaultImageURL"
        alt="Face Analysis Results"
      />
      <div class="w-[90%] h-auto flex flex-col gap-6 mb-8">
        <div class="flex flex-col gap-6 z-20">
          <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-2">
              <div class="font-[Inter] text-[#484848] text-[16px] font-semibold leading-[28px]">
                Face Analysis
              </div>
              <div class="font-[Campton] text-[#161616] text-[28px] font-semibold leading-[36px]">
                You have
                <span v-if="startsWithVowel(visionStore.diagResult?.faceShape?.faceShape)">an</span>
                <span v-else>a</span>
                {{ visionStore.diagResult?.faceShape?.faceShape }} shaped face,
                {{ visionStore.diagResult?.faceShape?.eyeColor }} eyes and
                {{ visionStore.diagResult?.faceShape?.hairLength }}
                {{ visionStore.diagResult?.faceShape?.hairColor }} hair.
              </div>
              <div>
                <FeatureCategory category="Face">
                  <FeatureItem
                    label="Shape"
                    :value="visionStore.diagResult?.faceShape?.faceShape"
                  />
                  <FeatureItem
                    label="Forehead"
                    :value="visionStore.diagResult?.faceShape?.foreheadSizes"
                  />
                </FeatureCategory>
                <FeatureCategory category="Skin">
                  <FeatureItem
                    label="Tone"
                    :value="visionStore.diagResult?.faceShape?.skinTone"
                  />
                  <FeatureItem
                    label="Undertone"
                    :value="visionStore.diagResult?.faceShape?.skinUnderTone"
                  />
                </FeatureCategory>
                <FeatureCategory category="Eyes">
                  <FeatureItem
                    label="Colour"
                    :value="visionStore.diagResult?.faceShape?.eyeColor"
                  />
                  <FeatureItem
                    label="Shape"
                    :value="visionStore.diagResult?.faceShape?.eyeShape"
                  />
                  <FeatureItem
                    label="Crease"
                    :value="visionStore.diagResult?.faceShape?.eyeCrease"
                  />
                  <FeatureItem
                    label="Lashes Length"
                    :value="visionStore.diagResult?.faceShape?.eyeLashesLength"
                  />
                  <FeatureItem
                    label="Eyebrow Shape"
                    :value="visionStore.diagResult?.faceShape?.eyeBrowShape"
                  />
                  <FeatureItem
                    label="Eyebrow Thickness"
                    :value="visionStore.diagResult?.faceShape?.eyeBrowsThickness"
                  />
                </FeatureCategory>
                <FeatureCategory category="Mouth">
                  <FeatureItem
                    label="Lips"
                    :value="visionStore.diagResult?.faceShape?.lipsDescription"
                  />
                  <FeatureItem
                    label="Width"
                    :value="visionStore.diagResult?.faceShape?.mouthWidth"
                  />
                </FeatureCategory>
                <FeatureCategory category="Hair">
                  <FeatureItem
                    label="Colour"
                    :value="visionStore.diagResult?.faceShape?.hairColor"
                  />
                  <FeatureItem
                    label="Length"
                    :value="visionStore.diagResult?.faceShape?.hairLength"
                  />
                  <FeatureItem
                    label="State"
                    :value="visionStore.diagResult?.faceShape?.hairState"
                  />
                </FeatureCategory>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVisionStore, useModalControllerStore } from '@/store'
import { onMounted } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { Modals } from '@/types/modal'
import FeatureCategory from '@/components/molecules/FeatureCategory.vue'
import FeatureItem from '@/components/molecules/FeatureItem.vue'

const modalStore = useModalControllerStore()
const visionStore = useVisionStore()

const defaultImageURL = '/images/Face-Analysis-Default-Image.png'

const startsWithVowel = (word: string | undefined): boolean => {
  if (!word) return false
  const firstLetter = word.toLowerCase().charAt(0)
  return ['a', 'e', 'i', 'o', 'u'].includes(firstLetter)
}

const onReScanClick = () => {
  modalStore.setPreSelfie('MAKEUP')
}

onMounted(() => {
  if (modalStore.runningModal === Modals.FACE_ANALYSIS && !visionStore.diagComplete)
    modalStore.setPreSelfie('MAKEUP')
})
</script>
