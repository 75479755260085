import { clients } from './client'
import { useAuthStore } from '@/store'
import { userHasNotConsented } from '@/utils/user-utils'
import { CRMBeautyExperienceData } from '@/types/crm'

const beautyExperience = async (data: CRMBeautyExperienceData) => {
  const authStore = useAuthStore()
  if (userHasNotConsented(authStore)) return
  if (!(await authStore.checkToken())) return
  await clients.bff.post('/crm/beautyExperience', data)
}

export default { beautyExperience }
