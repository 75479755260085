import type { SelfieImages } from '@/types/selfie'
import type { DiagResult } from '@/types/diag'
import { defineStore } from 'pinia'
import { VisionAPI } from '@/api'
import _ from 'lodash'

// Session storage has a limit of 5Mb, so we limit the number of selfies to 3
const SELFIE_STORE_LIMIT = 3

export const useSelfieStore = defineStore('selfies', {
  state: () => ({
    selfies: {} as SelfieImages
  }),
  getters: {
    hasSelfie(state): boolean {
      return _.size(state.selfies) > 0
    },
    latestSelfie(state): string | null {
      if (_.isEmpty(state.selfies)) return null
      const latestSelfie = _.last(_.sortBy(state.selfies, 'createdAt'))
      return latestSelfie!.selfieImageUrl ?? null
    }
  },
  actions: {
    pushSelfie(selfieImageUrl: string): string {
      if (!selfieImageUrl) {
        throw new Error('Invalid selfie image')
      }
      if (_.size(this.selfies) === SELFIE_STORE_LIMIT) {
        const oldestSelfie = _.first(_.sortBy(this.selfies, 'createdAt'))!.id
        delete this.selfies[oldestSelfie]
      }
      const selfieId = crypto.randomUUID()
      this.selfies[selfieId] = {
        id: selfieId,
        createdAt: new Date(),
        selfieImageUrl: selfieImageUrl
      }
      return selfieId
    },
    async submitSelfie(selfieId: string): Promise<DiagResult | null> {
      const selfie = this.selfies[selfieId]?.selfieImageUrl
      if (!selfie) {
        throw new Error('Invalid selfie image')
      }
      const results = await VisionAPI.postDiagBase64(selfie)
      if (
        results === '' ||
        !results.skinDiag ||
        results.skinDiag.message ||
        !results.faceShape ||
        results.faceShape.message
      ) {
        delete this.selfies[selfieId]
        return null
      }
      return results
    }
  }
})
