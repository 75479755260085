import { userHasNotConsented } from '@/utils/user-utils'
import { useAdminStore } from './adminStore'
import { useAuthStore } from './authStore'
import { useChatStore } from './chatStore'
import { useFlagStore } from './flagStore'
import { useMissionStore } from './missionStore'
import { useModalControllerStore } from './modalStore'
import { usePopularQuestionStore } from './popularQuestionStore'
import { useProductStore } from './productStore'
import { useSelfieStore } from './selfieStore'
import { useUIStore } from './uiStore'
import { useVisionStore } from './visionStore'
import { useErrorStore } from './errorStore'
import { useRoutineStore } from './routineStore'
import { FlagAPI, StartAPI } from '@/api'
import { logError } from '@/utils/errorUtils'
import type { Flag } from '@/types/flag'

const useStores = () => ({
  adminStore: useAdminStore(),
  authStore: useAuthStore(),
  chatStore: useChatStore(),
  flagStore: useFlagStore(),
  missionStore: useMissionStore(),
  popularQuestionStore: usePopularQuestionStore(),
  visionStore: useVisionStore()
})

/**
 * Initialize Pinia stores i.e. fetch necessary data from bff.
 */
const initStores = async () => {
  const stores = useStores()
  const errorStore = useErrorStore()
  let response = null
  try {
    response = await StartAPI.startVBC()
  } catch (e) {
    logError(e, 'failed to start up VBC')
    errorStore.errorMessage = 'Beauty Genius has failed to start up'
    errorStore.showErrorPage = true
  }
  if (!response) {
    errorStore.errorMessage = 'Beauty Genius has failed to start up'
    errorStore.showErrorPage = true
  }

  // getting user flags
  const consent = response?.flags.find((o: Flag) => o.flag === 'consent')?.value ?? false
  stores.authStore.setConsented(consent)
  // check user flags
  if (stores.authStore.user?.isAnonymous ?? false) await FlagAPI.setFlag('isAnonymous', true)
  stores.flagStore.setAllFlags(response?.flags ?? [])
  stores.missionStore.setMission(response?.missions ?? {})
  stores.popularQuestionStore.setQuestions(response?.questions ?? [])
  stores.visionStore.setDiag(response?.diagReslt ?? null)

  if (stores.adminStore.chatHistoryEnabled) {
    await stores.chatStore.getHistory()
  }

  if (!response?.sessionID) {
    errorStore.errorMessage = 'Beauty Genius has failed to start up'
    errorStore.showErrorPage = true
    return
  }

  stores.chatStore.startNewSession(response.sessionID)
  userHasNotConsented(stores.authStore, true)
  stores.chatStore.setReady()
}

export {
  useAdminStore,
  useAuthStore,
  useChatStore,
  useFlagStore,
  useMissionStore,
  useModalControllerStore,
  usePopularQuestionStore,
  useProductStore,
  useSelfieStore,
  useUIStore,
  useVisionStore,
  useErrorStore,
  useRoutineStore,
  initStores
}
