<template>
  <button
    class="w-[285px] h-[380px] mx-6 my-1"
    @click="emit('click', takeSelfie)"
  >
    <div class="w-full h-full relative rounded-xl">
      <div class="w-full h-full absolute left-0 top-0">
        <img
          class="w-full h-full object-cover rounded-xl"
          :src="imageURLRef"
          alt="selfie scan button"
        />
      </div>
      <div
        class="w-full h-full absolute left-0 top-0 bg-gradient-to-b from-[#12121255] to-[#121212ee] rounded-xl"
      />
      <div class="w-full h-full absolute left-0 top-0">
        <div class="p-[16px] h-full w-full text-left text-[#FFF]">
          <div
            class="flex flex-col h-full w-full justify-between"
            v-if="imageURLRef === defaultImageURL"
          >
            <SvgIcon name="camera-frame" />
            <div class="flex flex-col gap-[12px] w-full justify-between items-start">
              <div class="flex flex-col gap-1">
                <div class="font-[Campton] text-xl font-[600]">
                  {{ titleTextRef }}
                </div>
                <div class="font-[Inter] text-sm">
                  {{ bodyTextRef }}
                </div>
              </div>
              <button
                class="font-[Inter] text-[14px] text-[#161616] font-semibold leading-[18px] px-[16px] rounded-full w-full h-[48px] bg-[#eeeeee]"
              >
                Analyse
              </button>
            </div>
          </div>
          <div
            class="flex flex-col h-full w-full justify-between"
            v-else
          >
            <SvgIcon name="camera-frame" />
            <div
              v-if="chatStore.skinDiag === 'SKINCARE'"
              class="flex flex-col gap-[16px]"
            >
              <div class="flex flex-row justify-between items-start">
                <div class="flex flex-col gap-1">
                  <div class="font-[inter] text-[#BEBEBE] text-[14px] font-semibold leading-[20px]">
                    Skin Analysis Results
                  </div>
                  <div class="font-[Campton] text-[#FFF] text-[20px] font-semibold leading-[28px]">
                    {{ visionStore.overallDiag }}
                  </div>
                </div>
              </div>
              <button
                class="font-[Inter] text-[14px] text-[#161616] font-semibold leading-[18px] px-[16px] rounded-full w-full h-[48px] bg-[#eeeeee]"
              >
                Results
              </button>
            </div>
            <div
              v-else
              class="flex flex-col gap-[4px]"
            >
              <div class="flex flex-row justify-between items-start">
                <div class="flex flex-col gap-1">
                  <div class="font-[inter] text-[#BEBEBE] text-[10px] font-semibold leading-4">
                    Face Analysis
                  </div>
                  <div class="font-[Campton] text-[#FFF] text-[20px] font-semibold leading-[28px]">
                    Complete
                  </div>
                </div>
              </div>
              <button
                class="font-[Inter] text-[14px] text-[#161616] font-semibold leading-[18px] px-[16px] rounded-full w-full h-[48px] bg-[#eeeeee]"
              >
                View Results
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useVisionStore, useChatStore, useSelfieStore } from '@/store'
import { computed, onMounted, ref, watch } from 'vue'

const selfieStore = useSelfieStore()
const chatStore = useChatStore()
const visionStore = useVisionStore()

const takeSelfie = computed(() => {
  if (imageURLRef.value === defaultImageURL) return true
  return false
})

const emit = defineEmits(['click'])

const defaultImageURL =
  chatStore.skinDiag === 'SKINCARE'
    ? '/images/skin-scan-image.png'
    : '/images/makeup-scan-image.png'

const imageURLRef = ref<string>(defaultImageURL)

const titleTextRef = ref('Face Analysis')

const bodyTextRef = ref(
  'Our advanced technology analyzes your facial structure to create personalized recommendations.'
)

onMounted(() => {
  if (chatStore.skinDiag === 'SKINCARE') {
    titleTextRef.value = 'Your Skin Analysis'
    bodyTextRef.value =
      'With just one selfie, our advanced technology analyzes 8 key aspects of your skin.'
  }
})

watch(
  () => selfieStore.latestSelfie,
  () => {
    if (selfieStore.hasSelfie) {
      const selfie = selfieStore.latestSelfie
      imageURLRef.value = selfie ?? defaultImageURL
    } else {
      imageURLRef.value = defaultImageURL
    }
  },
  { immediate: true }
)
</script>
