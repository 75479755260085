<template>
  <template
    v-for="(message, index) in messages"
    :key="index"
  >
    <ChatMessageSeparator :content="message.sessionIdentifier" />
    <UserMessage
      v-if="isUserTextMessage(message)"
      :content="message.content"
    />
    <AIMessage
      v-if="isAIMessage(message)"
      :message-id="!message.error ? message.id : ''"
      :content="message.content"
      :is-current-message="chatStore.isCurrentChatMessage(message.id)"
      :complete="message.complete"
      :feedback-selected="onFeedbackSelected(message)"
      @like="openFeedbackWindow(message.id, 'positive', message.content)"
      @dislike="openFeedbackWindow(message.id, 'negative', message.content)"
    >
      <Disclaimer
        v-if="
          isReady(message) &&
          isAIMessage(message) &&
          !_.isEmpty(message.disclaimer) &&
          (!chatStore.typing || !chatStore.isCurrentChatMessage(message.id))
        "
        :content="message.disclaimer"
      />
    </AIMessage>
    <SelfieScanButton
      v-if="
        isReady(message) &&
        isAIMessage(message) &&
        (isSkinDiagButton(message, index) || chatStore.showUserImageAfterSelfie(message.id)) &&
        (!chatStore.typing || !chatStore.isCurrentChatMessage(message.id))
      "
      @click="(takeSelfie) => onSelfieScanButtonClick(takeSelfie)"
      data-testid="diagnose-skin-button"
    />
    <ChatRoutineCard
      v-if="
        isReady(message) &&
        isAIMessage(message) &&
        !_.isEmpty(message.routine) &&
        (!chatStore.typing || !chatStore.isCurrentChatMessage(message.id))
      "
      :routine="message.routine"
      :routine-recommendation-id="message.routineRecommendationId"
      :product-upcs="message?.productUpcs || []"
      :msg-index="index"
    />
    <ProductCardContainer
      v-else-if="
        isReady(message) &&
        isAIMessage(message) &&
        (!chatStore.typing || !chatStore.isCurrentChatMessage(message.id))
      "
      :msg-index="index"
      :message="message"
      :product-upcs="message?.productUpcs || []"
      :triggered-by-admin="
        adminStore.vtoMessageNumber !== null && Math.ceil(index / 2) === adminStore.vtoMessageNumber
      "
      @vto="(upc) => setVto(upc, index)"
    />
    <ImageContainer
      v-if="
        isAIMessage(message) &&
        chatStore.isCurrentChatMessage(message.id) &&
        (chatStore.suggestedResponses.length ?? 0) > 0 &&
        !chatStore.suggestedResponsesFlag &&
        !chatStore.textEntryDisabled
      "
    />
  </template>
</template>

<script lang="ts">
import { ChatMessage, ChatMessageAI } from '@/types/chats'

interface IChatMessagesProps {
  messages: ChatMessage[]
}
</script>

<script setup lang="ts">
import _ from 'lodash'
import UserMessage from '@/components/chat/UserMessage.vue'
import ProductCardContainer from '@/components/molecules/ProductCardContainer.vue'
import ImageContainer from '@/components/molecules/ImageContainer.vue'
import { isAIMessage, isReady, isUserTextMessage } from '@/utils/messages-utils'
import ChatRoutineCard from '@/components/molecules/ChatRoutineCard.vue'
import AIMessage from '@/components/chat/AIMessage.vue'
import { useChatStore } from '@/store/chatStore'
import { useModalControllerStore } from '@/store/modalStore'
import { useAdminStore, useUIStore } from '@/store'
import { FeedbackOverall } from '@/types/feedback'
import ChatMessageSeparator from '@/components/atoms/ChatMessageSeparator.vue'
import Disclaimer from '@/components/chat/Disclaimer.vue'
import { watch } from 'vue'
import SelfieScanButton from '@/components/molecules/SelfieScanButton.vue'

const chatStore = useChatStore()
const modalControllerStore = useModalControllerStore()
const adminStore = useAdminStore()
const uiStore = useUIStore()

defineProps<IChatMessagesProps>()

const setVto = (upc: string, msgIndex: number) => {
  const messageUPCS = chatStore.getProductsFromMessage(msgIndex)
  const upcs = _.isEmpty(messageUPCS) ? [upc] : messageUPCS
  modalControllerStore.setVTO({ upcs, msgIndex: msgIndex, selected: upc })
}

const openFeedbackWindow = async (messageId: string, overall: FeedbackOverall, message: string) => {
  if (messageId !== '') {
    chatStore.setFeedback({ messageId, overall, message })
    modalControllerStore.setFeedback()
  }
}

const onFeedbackSelected = (message: ChatMessage) => {
  if (chatStore.feedback && chatStore.feedback.messageId === message.id) {
    return chatStore.feedback
  }
  return undefined
}

const isSkinDiagButton = (message: ChatMessageAI, index: number) => {
  if (
    adminStore.skinDiagMessageNumber !== null &&
    Math.ceil(index / 2) === adminStore.skinDiagMessageNumber
  ) {
    return true
  }
  return message.complete && message.skinDiag
}

const onSelfieScanButtonClick = (takeSelfie: boolean) => {
  if (takeSelfie) modalControllerStore.setPreSelfie(chatStore.skinDiag)
  else if (chatStore.skinDiag === 'SKINCARE') modalControllerStore.setSkinDiag()
  else modalControllerStore.setFaceAnalysis()
}
watch(
  () => chatStore.typing,
  () => {
    if (!chatStore.typing) uiStore.setScrollToBottom(true)
  }
)
</script>
