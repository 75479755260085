<template>
  <div class="w-[85%] grid grid-cols-2 gap-4 px-4 mt-4">
    <div
      v-for="image of processedImages"
      :key="image.url"
      class="w-full border-[1px] border-[#bebebe] rounded-[16px]"
    >
      <img
        :src="image.url"
        :alt="image.caption"
        class="w-full h-44 object-cover rounded-[16px] p-1"
        @click="selectResponse(image)"
      />
      <div
        class="flex flex-row justify-between items-center px-3 h-[30px]"
        @click="selectResponse(image)"
      >
        <p class="font-[Inter] font-[600] text-[12px] leading-[18px]">{{ image.caption }}</p>
        <div class="w-[20px]">
          <SvgIcon
            color="black"
            name="arrow-send"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { ProcessedImage } from '@/types/products'
import { useChatStore, useUIStore } from '@/store'
import { tracking } from '@/tracking/EventController'
import { SuggestedResponse } from '@/types/chats'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

const chatStore = useChatStore()
const uiStore = useUIStore()
const processedImages = ref<(ProcessedImage & SuggestedResponse)[]>([])

onMounted(() => {
  processedImages.value = chatStore.suggestedResponses
})

const selectResponse = async (response: SuggestedResponse) => {
  tracking.selectFollowupMessageEvent(chatStore.currentSessionId, response.response)
  chatStore.setSuggestedResponsesFlag(false)
  chatStore.postMessage({ prompt: response.response })
  uiStore.setScrollToBottom(true)
}
</script>
