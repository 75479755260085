import { clients } from './client'
import { useAuthStore } from '@/store'
import { Startup } from '@/types/startup'

const startVBC = async (): Promise<Startup | null> => {
  const authStore = useAuthStore()
  if (!(await authStore.checkToken())) return null
  const response = await clients.bff.get('/start')
  return response.data as Startup
}

export default { startVBC }
