import { createApp } from 'vue'
import { router } from './router/routes'
import * as Sentry from '@sentry/vue'

const DEFAULT_SENTRY_ENV = 'local'

const initSentry = (app: ReturnType<typeof createApp>) => {
  const sentryEnv = import.meta.env.VITE_SENTRY_ENV ?? DEFAULT_SENTRY_ENV
  if (sentryEnv === DEFAULT_SENTRY_ENV) return
  try {
    Sentry.init({
      app,
      release: `beautygenius-client@${VITE_APP_VERSION}`,
      environment: sentryEnv,
      dsn: 'https://fb9b74213e26fa2b384af95e37ba17b0@o4505957955796992.ingest.sentry.io/4505957967003648',
      integrations: [
        // Add browser profiling integration to the list of integrations
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: sentryEnv === 'local' ? 0 : 0.2,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/vbc-*\.beauty\.tech/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      // Set profilesSampleRate to 1.0 to profile every transaction.
      // Since profilesSampleRate is relative to tracesSampleRate,
      // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      // result in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0
    })
  } catch (error) {
    console.warn('Failed to initiate Sentry connection.')
  }
}

export { initSentry, DEFAULT_SENTRY_ENV }
