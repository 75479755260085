<template>
  <div class="relative w-full h-full flex justify-center page-offwhite pb-2">
    <div
      class="z-30 drop-shadow h-[40px] absolute top-5 right-5 flex flex-row gap-2 bg-[#FFFFFF] text-[#000000] w-[120px] justify-center items-center rounded-full font-[Inter] px-2 py-[12px] text-[14px] leading-[18px] font-semibold"
      @click="onReScanClick"
    >
      <SvgIcon
        name="rescan"
        color="black"
      />
      Rescan
    </div>
    <div class="h-full flex flex-col items-center gap-6 overflow-y-auto overflow-x-hidden">
      <img
        class="w-full object-cover object-top"
        :src="defaultImageURL"
        alt="Skin Diag Results"
      />
      <div class="w-[90%] h-auto flex flex-col gap-[24px]">
        <div class="flex flex-col gap-6 z-20">
          <div class="flex flex-col gap-[24px]">
            <div class="flex flex-col gap-[8px]">
              <div class="font-[Inter] text-[#484848] text-[16px] font-semibold leading-[28px]">
                Skin Analysis
              </div>
              <div class="font-[Campton] text-[#161616] text-[28px] font-semibold leading-[36px]">
                {{ visionStore.overallDiag }}
              </div>
              <div class="font-[Inter] text-[#484848] text-sm">
                The analysis found some strengths and a few areas we can improve on together.
              </div>
            </div>
            <div
              class="flex flex-row gap-[8px] px-[16px]"
              @click="openSkinInfo"
            >
              <SvgIcon name="info" />
              <div class="font-[Inter] text-[#161616] text-[14px] font-semibold leading-[18px]">
                More Info
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-[8px]">
          <template
            v-for="(diag, index) in visionStore.skinScores"
            :key="index"
          >
            <div
              v-if="diagTextMapping.skinDiag[diag.sign]"
              class="rounded-lg bg-white p-[16px] w-full drop-shadow"
            >
              <div class="flex flex-row gap-4">
                <img
                  :src="diagTextMapping.skinDiag[diag.sign].image"
                  :alt="diagTextMapping.skinDiag[diag.sign].name"
                  class="h-[40px] w-[40px] rounded-full"
                />
                <div class="flex flex-col w-full gap-4">
                  <CollapsableSection>
                    <template #header>
                      <div class="flex flex-col gap-1">
                        <div
                          class="font-[Inter] text-[#484848] font-semibold text-[12px] leading-[18px]"
                        >
                          {{ diagTextMapping.skinDiag[diag.sign].name }}
                        </div>
                        <div
                          class="text-[16px] leading-[28px] font-[Campton] text-[#161616] font-semibold"
                        >
                          {{ scoreToLevel(diag.sign, diag.score) }}
                        </div>
                      </div>
                    </template>
                    <template #divider>
                      <ScoreBar :score="diag.score" />
                    </template>
                    <template #content>
                      <div class="font-[Inter] text-[#484848] text-sm">
                        {{ diagTextMapping.skinDiag[diag.sign].description }}
                      </div>
                    </template>
                  </CollapsableSection>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVisionStore, useModalControllerStore } from '@/store'
import { onMounted } from 'vue'
import { diagTextMapping } from '@/types/diag.ts'
import ScoreBar from '@/components/atoms/ScoreBar.vue'
import CollapsableSection from '@/components/layout/CollapsableSection.vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { Modals } from '@/types/modal'

const modalStore = useModalControllerStore()
const visionStore = useVisionStore()

const defaultImageURL = '/images/skin-results-image.png'

const scoreToLevel = (sign: string, score: number): string => {
  const levels = diagTextMapping.skinDiag[sign].levels
  if (score < 0.2) return levels[0]
  else if (score < 0.6) return levels[1]
  else if (score < 0.9) return levels[2]
  else return levels[3]
}

const onReScanClick = () => {
  modalStore.setPreSelfie()
}

const openSkinInfo = () => {
  modalStore.setSkinInfo()
}

onMounted(() => {
  if (modalStore.runningModal === Modals.SKINDIAG && !visionStore.diagComplete)
    modalStore.setPreSelfie('SKINCARE')
})
</script>
