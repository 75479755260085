import { capitalize } from 'lodash'

/**
 * Add space to the provided string. The current method works only for strings in CamalCase or snake_case.
 *
 * @param s - Input string to add spacing to.
 */
export const spacing = (s: string): string =>
  s
    .replaceAll('_', ' ')
    .replaceAll('-', ' ')
    .split(/(?=[A-Z])/)
    .join(' ')

/**
 * Split the string according to brackets. Notice that this function won't work for nested brackets.
 *
 * Example: splitBrackets('string 0 [string 1] string 2 [string 3]') === ['string 0', '[string 1]', 'string 2', '[string 3]']
 */
export const splitBrackets = (s: string, left: string = '[', right: string = ']'): string[] =>
  s
    .split(new RegExp(`[\\${left}]`)) // look for left bracket
    .flatMap((sub) => sub.split(new RegExp(`[\\${right}]`))) // look for right bracket

/**
 * Replace the given brackets in the given string with the provided strings.
 */
export const replaceBrackets = (
  s: string,
  fromLeft: string,
  toLeft: string,
  fromRight = fromLeft,
  toRight = toLeft
): string => {
  let result = s
  // Find the first occurrence of the string to replace
  const firstIndex = result.indexOf(fromLeft)
  // Find the last occurrence of the string to replace
  let lastIndex = result.lastIndexOf(fromRight)
  // Check if the string to replace exists at least once
  if (firstIndex !== -1 && lastIndex !== -1) {
    // If the first and last occurrences are the same, replace only the first one
    if (firstIndex === lastIndex) {
      result = result.slice(0, firstIndex) + toLeft + result.slice(firstIndex + toLeft.length)
    } else {
      // Replace the first occurrence
      result = result.slice(0, firstIndex) + toLeft + result.slice(firstIndex + toLeft.length)
      // Update the last index since we modified the string
      lastIndex = result.lastIndexOf(fromRight)
      // Replace the last occurrence
      result = result.slice(0, lastIndex) + toRight + result.slice(lastIndex + toRight.length)
    }
  }
  return result
}

/**
 * Remove the given brackets from the given string.
 */
export const removeBrackets = (s: string, left: string, right = left): string => {
  return replaceBrackets(s, left, '', right, '')
}

export const splitInt = (input: string, split: string): Array<number> => {
  return input.split(split).map(function (element) {
    const intElement = parseInt(element, 10)
    if (isNaN(intElement)) {
      throw new Error(`splitInt: input contains a non number ${element}`)
    }
    return intElement
  })
}

export const listToStr = (list: string[]): string => capitalize(list.join(', '))

export const stringEmpty = (str: string) => /^\s*$/.test(str)
