<template>
  <div
    ref="cdnRef"
    class="w-full h-full overflow-y-auto pt-20 px-2 relative bg-[#FAFAFA]"
  >
    <div
      ref="loadRef"
      :id="id"
    />
  </div>
</template>

<script lang="ts">
import { createOneTrustCDNScript, createOneTrustLoadScript } from '@/utils/onetrust'

interface IInformationPageProps {
  id: string
  content: string
}
</script>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

const cdnRef = ref<HTMLDivElement | null>(null)
const loadRef = ref<HTMLDivElement | null>(null)

const props = defineProps<IInformationPageProps>()

onMounted(() => {
  if (cdnRef.value) {
    const cdnScript = createOneTrustCDNScript()
    cdnRef.value.appendChild(cdnScript)
    cdnScript.onload = () => {
      if (!loadRef.value) return
      const loadScript = createOneTrustLoadScript(props.content)
      loadRef.value.appendChild(loadScript)
    }
  }
})

onUnmounted(() => {
  if (cdnRef.value) {
    cdnRef.value.innerHTML = ''
  }
  if (loadRef.value) {
    loadRef.value.innerHTML = ''
  }
})
</script>
