<template>
  <ProductCard
    :loading="loading"
    :product="product"
    maximized
  />
</template>

<script lang="ts">
interface IProductCardModalProps {
  upc: string
  msgIndex: number
  prodIndex: number
  sendEvent: boolean
}
</script>

<script setup lang="ts">
import { type Ref, watch, ref, onMounted } from 'vue'
import type { ProductData } from '@/types/products'
import { useProductStore, useChatStore, useModalControllerStore } from '@/store'
import ProductCard from '@/components/molecules/ProductCard.vue'
import { tracking } from '@/tracking/EventController'
import { type eventProduct } from '@/types/tracking'
import { getProductName } from '@/utils/product'

const productStore = useProductStore()
const chatStore = useChatStore()
const modalStore = useModalControllerStore()

const product: Ref<ProductData> = ref({} as ProductData)
const loading = ref(false)

/* Props */
const props = withDefaults(defineProps<IProductCardModalProps>(), {
  upc: '',
  msgIndex: -1,
  prodIndex: 0,
  sendEvent: false
})

const sendEvent = () => {
  const trackedProduct: eventProduct = {
    id: product?.value.upc ?? '',
    brand: product?.value.brandCode ?? '',
    variant: product?.value.franchise,
    name: getProductName(product.value) ?? '',
    position: props?.prodIndex,
    category: product?.value.productType ?? '',
    price: product?.value.eCommerce?.price ?? '',
    list: 'coach'
  }
  tracking.productSelectEvent(chatStore.currentSessionId, trackedProduct)
}

/* lifecycle hooks */
onMounted(async () => {
  loading.value = true
  const products = await productStore.getProducts([props.upc])
  product.value = products[0]
  if (props.sendEvent) sendEvent()
  if (modalStore.productCard) modalStore.productCard.sendEvent = false
  loading.value = false
})

watch(
  () => props.upc,
  async () => {
    loading.value = true
    const products = await productStore.getProducts([props.upc])
    product.value = products[0]
    sendEvent()
    loading.value = false
  }
)
</script>
