<template>
  <div class="h-[16px] bg-[#f5f1ea] w-full flex flex-row rounded-3xl items-center p-[2px]">
    <div
      class="h-[12px] relative custom-bar-gradient rounded-3xl"
      :style="{ width: barWidth }"
    />
  </div>
</template>

<script lang="ts">
interface IScoreBarProps {
  score: number
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<IScoreBarProps>(), {
  score: 0
})

const barWidth = computed(() => {
  const percScore = props.score * 100
  if (percScore < 0) return '0%'
  else if (percScore > 100) return '100%'
  return percScore + '%'
})
</script>

<style scoped>
.custom-bar-gradient {
  background: linear-gradient(to right, #f9e3c2 0%, #f2cd95 100%);
}
</style>
