import { defineStore } from 'pinia'
import type { Admin } from '@/types/admin'

export const useAdminStore = defineStore('admin', {
  state: () =>
    ({
      vtoMessageNumber: null,
      skinDiagMessageNumber: null,
      chatHistoryEnabled: true,
      skinDiagResults: true,
      showBeautyState: false,
      underMaintenance: true
    }) as Admin,
  persist: {
    storage: sessionStorage
  }
})
