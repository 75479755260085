<template>
  <Loading v-if="isLoading" />
  <DetailedRoutineCard
    v-else
    data-testid="routine-modal"
    :routine="routine"
    :msg-index="msgIndex"
  />
</template>

<script lang="ts">
import type { GenAIRoutine } from '@/types/routine'

interface IRoutineModalProps {
  routine: GenAIRoutine
  msgIndex: number
}
</script>

<script setup lang="ts">
import { onBeforeMount, onUnmounted, ref } from 'vue'
import DetailedRoutineCard from '@/components/molecules/DetailedRoutineCard.vue'
import Loading from '@/components/atoms/Loading.vue'
import { useProductStore, useRoutineStore } from '@/store'

const productStore = useProductStore()
const routineStore = useRoutineStore()

const props = defineProps<IRoutineModalProps>()

const isLoading = ref(true)

const getProducts = async () => {
  const upcsSet: Set<string> = new Set()
  for (const timeOfDay of ['morning', 'evening'] as const) {
    for (const item of props.routine[timeOfDay]) {
      if (item.upc) {
        upcsSet.add(item.upc)
      }
    }
  }
  const upcs: string[] = Array.from(upcsSet)

  productStore.hydrateProducts(upcs, props.routine)
}

onBeforeMount(async () => {
  if (props.msgIndex === -1) {
    isLoading.value = true
    await getProducts()
  }
  isLoading.value = false
})

onUnmounted(async () => {
  routineStore.resetLoadedFromUrl()
})
</script>
