<template>
  <div class="w-full h-full relative bg-[#FAFAFA] overflow-y-scroll">
    <div class="w-full h-full flex flex-col">
      <div class="flex flex-col items-center justify-center text-center h-[255px] bg-black">
        <div
          v-if="userName"
          class="w-full h-full flex flex-col justify-center items-center text-white text-[32px] font-[Campton] font-[600] leading-[40px] gap-[16px]"
        >
          <div class="w-full px-2 truncate">
            {{ userName }}
          </div>
          <button
            class="flex w-[128px] h-[48px] p-[16px] justify-center items-center gap-[8px] rounded-[200px] border border-[#BEBEBE] bg-[#00000033]"
            @click="editName"
          >
            <SvgIcon
              name="pen"
              color="#FFFFFF"
            />
            <span class="text-[#FFFFFF] font-[Inter] text-[14px] font-[600] leading-[18px]">
              Edit
            </span>
          </button>
        </div>
        <div
          v-else
          class="text-white text-[32px] font-[Campton] font-[600] leading-[40px]"
        >
          Anonymous
        </div>
      </div>
      <div class="w-full flex flex-col flex-grow justify-between p-[16px]">
        <div class="w-full flex flex-col gap-[8px]">
          <div>
            <button
              class="custom-profile-item rounded-none"
              @click="openSelfieModal"
            >
              Scan my face
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </button>
            <button
              class="custom-profile-item rounded-none"
              @click="openSkinResults"
            >
              Skin Results
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </button>
            <button
              class="custom-profile-item rounded-none"
              @click="openFaceResults"
            >
              Beauty Features
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </button>
          </div>
          <div v-if="adminStore.showBeautyState && beautyState">
            <div class="beauty-state-profile-item pt-[16px] pl-[12px] pb-[12px] capitalize">
              Current Beauty State:
            </div>
            <div
              v-for="(value, key) in beautyState"
              :key="key"
              class="beauty-state-profile-item text-[#8a8a8a] pl-[32px]"
            >
              {{ key.split('_').join(' ') }}: {{ !isEmpty(value) ? value : 'n/a' }}
            </div>
          </div>
          <div v-if="!authStore.isAnonymous">
            <button
              class="custom-profile-item rounded-none"
              @click="confirmAccountDeletion"
            >
              Delete account
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </button>
            <div class="custom-profile-item rounded-none">
              <div class="text-[#8a8a8a]">Change password *</div>
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </div>
          </div>
          <div>
            <button
              class="custom-profile-item rounded-none"
              @click="openInformationpages"
            >
              <div>T&Cs</div>
              <SvgIcon
                name="button-click-arrow"
                color="#000000"
              />
            </button>
            <div class="custom-profile-item rounded-none">
              <div class="text-[#8a8a8a]">Support *</div>
              <SvgIcon
                name="open-url-arrow"
                color="#000000"
              />
            </div>
          </div>
          <div class="text-[#8a8a8a]">*: Coming soon</div>
        </div>
        <button
          v-if="!authStore.isAnonymous"
          class="flex flex-col h-[48px] px-[16px] justify-center items-center rounded-[4px] border border-[#BEBEBE] bg-[#FFFFFF33] text-[#161616] font-[Campton] text-[14px] font-[600] leading-[18px] uppercase"
          @click="signOut"
        >
          LOGOUT
        </button>
        <button
          v-else-if="disableLogin !== 'true'"
          class="flex flex-col h-[48px] px-[16px] justify-center items-center rounded-[4px] border border-[#BEBEBE] bg-[#FFFFFF33] text-[#161616] font-[Campton] text-[14px] font-[600] leading-[18px] uppercase"
          @click="logIn"
        >
          LOGIN
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore, useAuthStore, useModalControllerStore } from '@/store'
import { computed, onMounted, ref } from 'vue'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import { useRouter } from 'vue-router'
import FirebaseAuth from '@/auth/firebase'
import { ChatAPI } from '@/api'
import { BeautyState } from '@/types/diag'
import { isEmpty } from 'lodash'
import { getParentDomainAsUrlParam } from '@/utils/parentEvent'

const adminStore = useAdminStore()
const authStore = useAuthStore()
const auth = new FirebaseAuth(authStore)
const router = useRouter()
const modalControllerStore = useModalControllerStore()
const disableLogin = import.meta.env.VITE_DISABLE_LOGIN ?? 'false'
const beautyState = ref<BeautyState | null>(null)

onMounted(async () => {
  beautyState.value = await ChatAPI.getBeautyState()
})

const userName = computed(() => {
  if (!authStore.user) return ''
  if (authStore.user.displayName) {
    return authStore.user.displayName
  }
  return authStore.user.email ?? ''
})

const editName = () => {
  modalControllerStore.setEditName()
}

const signOut = async () => {
  await auth.signOut()
  modalControllerStore.closeModal()
  if (import.meta.env.VITE_ALLOW_ANONYMOUS === 'true') {
    window.location.reload()
  } else {
    const additionalParams = getParentDomainAsUrlParam()
    await router.replace({ name: 'auth', ...additionalParams })
  }
}

const logIn = () => {
  modalControllerStore.setLogin('chat')
}

const openSelfieModal = () => {
  modalControllerStore.setPreSelfie('SKINCARE')
}

const openSkinResults = () => {
  modalControllerStore.setSkinDiag()
}

const openFaceResults = () => {
  modalControllerStore.setFaceAnalysis()
}

const confirmAccountDeletion = async () => {
  modalControllerStore.setDeleteAccount()
}

const openInformationpages = () => {
  modalControllerStore.setInformationPage()
}
</script>

<style scoped lang="scss">
.custom-profile-item {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px 16px 12px;
  border-radius: 4px;
  color: #161616;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.beauty-state-profile-item {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
</style>
