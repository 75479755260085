import { useAuthStore } from '@/store'

export const verifyOrigin = (): string | null => {
  const authStore = useAuthStore()
  const parentURL = authStore.getParentURL() ?? ''
  try {
    const domainList: string[] = import.meta.env.VITE_DOMAIN_LIST?.split(' ')
    if (domainList.includes(parentURL)) {
      return parentURL
    }
    return null
  } catch (e) {
    return null
  }
}

export const parentCommand = (command: string, data: object) => {
  const domain = verifyOrigin()
  if (domain) window.parent.postMessage({ type: 'command', command, data }, domain)
}
export const parentEvent = (event: string, data: object) => {
  const domain = verifyOrigin()
  if (domain) window.parent.postMessage({ type: 'event', event, data }, domain)
}

export const getParentDomainAsUrlParam = () => {
  const authStore = useAuthStore()
  const parentDomain = authStore.getParentURL()
  return parentDomain ? { query: { parentDomain: authStore.getParentURL() } } : {}
}
