<template>
  <h1
    class="text-black text-[50px] w-full h-full font-bold text-center align-middle justify-center items-center flex"
  >
    {{ errorStore.errorMessage }}
  </h1>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/store/errorStore'
const errorStore = useErrorStore()
</script>
