import type { GenAIRoutine } from '@/types/routine'

export type VtoData = {
  upcs: string[]
  selected: string
  msgIndex?: number
}

export type ProductCardData = {
  upc: string
  msgIndex: number
  productIndex?: number
  sendEvent?: boolean
}

export type RoutineData = {
  routine: GenAIRoutine
  msgIndex: number
}

export enum Overlays {
  NONE,
  PRE_SELFIE,
  SELFIE,
  VTO,
  FEEDBACK,
  LOGIN,
  DELETE_ACCOUNT,
  SKIN_INFO
}

export enum Modals {
  NONE,
  TERMS_CONDS,
  INFORMATION_PAGE,
  INFORMATION_PAGES,
  SKINDIAG,
  FACE_ANALYSIS,
  ROUTINE,
  PROFILE,
  EDIT_NAME,
  PRODUCT
}
