import { defineStore } from 'pinia'
import type { ProductCardData, RoutineData, VtoData } from '@/types/modal'
import { Modals, Overlays } from '@/types/modal'
import { Pages } from '@/types/pages'
import { getElapsedMin, getTime } from '@/utils/time'
import { useChatStore } from './chatStore'
import _ from 'lodash'
import { tracking } from '@/tracking/EventController'
import { useAuthStore } from './authStore'
import { logError } from '@/utils/errorUtils'
import { informationPageContents } from '@/utils/onetrust'
import { userHasNotConsented } from '@/utils/user-utils'
import { useRoutineStore } from './routineStore'

export const useModalControllerStore = defineStore('modalController', {
  state: () => ({
    runningModal: Modals.NONE,
    runningOverlay: Overlays.NONE,
    previousModal: Modals.NONE,
    vto: null as VtoData | null,
    productCard: null as ProductCardData | null,
    PDPMenu: {
      upc: String,
      msgIndex: String
    },
    informationPage: {
      id: '',
      content: ''
    },
    routine: null as RoutineData | null,
    returnTo: [Modals.NONE],
    termsCondsIndex: 0,
    page: Pages.CHAT,
    SelfieType: 'SKINCARE' as string,
    canSkipLogin: false,
    goToAfterConsent: '',
    afterConsentArgs: {}
  }),
  actions: {
    setVTO(data: VtoData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/vto' },
        'Beauty Genius',
        '/vto?upcs=' + data['upcs']
      )
      this.PDPMenu = {
        upc: data.selected,
        msgIndex: data.msgIndex
      }
      this.vto = data
      this.runningOverlay = Overlays.VTO
      this.store(Overlays.VTO)
    },
    setProduct(data: ProductCardData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.returnTo.push(this.runningModal)
      window.history.pushState(
        { back: window.history.state.current, current: '/PDP' },
        'Beauty Genius',
        '/PDP?upc=' + data['upc']
      )
      this.PDPMenu = {
        upc: data.upc,
        msgIndex: data.msgIndex
      }
      this.productCard = data
      this.runningModal = Modals.PRODUCT
      this.store(Modals.PRODUCT)
    },
    setVtoFromProduct() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      if (!this.productCard) return
      const messageUPCS = useChatStore().getProductsFromMessage(this.productCard.msgIndex)
      const upcs = _.isEmpty(messageUPCS) ? [this.productCard.upc] : messageUPCS
      this.setVTO({ upcs, msgIndex: this.productCard.msgIndex, selected: this.productCard.upc })
    },
    setPreSelfie(selfieType: string) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.SelfieType = selfieType
      this.runningOverlay = Overlays.PRE_SELFIE
    },
    setSkinInfo() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/skinInfo' },
        'Beauty Genius',
        '/skinInfo'
      )
      this.runningOverlay = Overlays.SKIN_INFO
    },
    setSelfie() {
      tracking.faceScanEvent(useChatStore().currentSessionId)
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/scan' },
        'Beauty Genius',
        '/scan'
      )
      this.runningOverlay = Overlays.SELFIE
    },
    setFeedback() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.runningOverlay = Overlays.FEEDBACK
    },
    setSkinDiag() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/skinResults' },
        'Beauty Genius',
        '/skinResults'
      )
      this.returnTo.push(this.runningModal)
      this.runningModal = Modals.SKINDIAG
    },
    setFaceAnalysis() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/faceAnalysis' },
        'Beauty Genius',
        '/faceAnalysis'
      )
      this.returnTo.push(this.runningModal)
      this.runningModal = Modals.FACE_ANALYSIS
    },
    setRoutine(routineRecommendationId: string, data: RoutineData) {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/routine' },
        'Beauty Genius',
        '/routine?ref=' + routineRecommendationId
      )
      this.returnTo.push(this.runningModal)
      this.runningModal = Modals.ROUTINE
      this.routine = data
      this.store(Modals.ROUTINE)
    },
    setProfile() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      window.history.pushState(
        { back: window.history.state.current, current: '/profile' },
        'Beauty Genius',
        '/profile'
      )
      this.returnTo.push(this.runningModal)
      this.runningModal = Modals.PROFILE
    },
    setLogin(path: string, args = null as null | string, allowSkip = false) {
      let url = '/login?path=' + path
      if (args && args !== '{}') url = url + '&args=' + args
      window.history.pushState(
        { back: window.history.state.current, current: '/login' },
        'Beauty Genius',
        encodeURI(url)
      )
      this.canSkipLogin = allowSkip
      this.runningOverlay = Overlays.LOGIN
    },
    setDeleteAccount() {
      window.history.pushState(
        { back: window.history.state.current, current: '/deleteAccount' },
        'Beauty Genius',
        '/deleteAccount'
      )
      this.runningOverlay = Overlays.DELETE_ACCOUNT
    },
    setEditName() {
      const authStore = useAuthStore()
      if (userHasNotConsented(authStore)) return
      this.returnTo.push(Modals.PROFILE)
      this.runningModal = Modals.EDIT_NAME
    },
    setTermsConds() {
      if (this.runningModal === Modals.TERMS_CONDS) return
      window.history.pushState(
        { back: window.history.state.current, current: '/termsConds' },
        'Beauty Genius',
        '/termsConds'
      )
      this.returnTo.push(this.runningModal)
      this.runningModal = Modals.TERMS_CONDS
    },
    setInformationPage(page?: string) {
      try {
        if (page) {
          const section = informationPageContents()[page]
          if (section) {
            window.history.pushState(
              { back: window.history.state.current, current: '/information' },
              'Beauty Genius',
              '/information?page=' + page
            )
            this.informationPage = section
            this.returnTo.push(this.runningModal)
            this.runningModal = Modals.INFORMATION_PAGE
          }
        } else {
          window.history.pushState(
            { back: window.history.state.current, current: '/information' },
            'Beauty Genius',
            '/information'
          )
          this.returnTo.push(this.runningModal)
          this.runningModal = Modals.INFORMATION_PAGES
        }
      } catch (error) {
        logError(error, 'Error setting information page')
      }
    },
    store(modal: Modals | Overlays) {
      window.localStorage.setItem(
        'modalHistory',
        JSON.stringify({
          modal: modal,
          runOrder: this.returnTo,
          createdAt: getTime(),
          data: {
            [Modals.ROUTINE]: this.routine,
            [Modals.PRODUCT]: this.productCard,
            [Overlays.VTO]: this.vto
          }
        })
      )
    },
    reload(reloadData: string | null) {
      if (!reloadData) return
      const data = JSON.parse(reloadData)
      if (!data || getElapsedMin(data.createdAt) > 2) return
      this.routine = data.data[Modals.ROUTINE] ?? null
      this.vto = data.data[Overlays.VTO] ?? null
      this.productCard = data.data[Modals.PRODUCT] ?? null
      this.returnTo = data.runOrder ?? null
    },
    previous() {
      return this.previousModal
    },
    back() {
      const previous = this.returnTo.pop()
      this.previousModal = this.runningModal
      if (!useAuthStore().consented) return this.setTermsConds()
      if (previous === Modals.NONE || previous === null || previous === undefined)
        return this.closeModal()
      this.runningModal = previous
      this.updateURLBasedOnModal()
    },
    updateURLBasedOnModal() {
      switch (this.runningModal) {
        case Modals.NONE: {
          this.updateURL('/chat')
          break
        }
        case Modals.PRODUCT: {
          this.store(Modals.PRODUCT)
          const upc = this.productCard?.upc ?? ''
          if (this.productCard) this.productCard.sendEvent = false
          this.updateURL('/PDP', '?upc=' + upc)
          break
        }
        case Modals.ROUTINE: {
          this.store(Modals.ROUTINE)
          const routineStore = useRoutineStore()
          this.updateURL('/routine', '?ref=' + routineStore.currentRoutineId)
          break
        }
        case Modals.PROFILE: {
          this.updateURL('/profile')
          break
        }
        case Modals.TERMS_CONDS: {
          this.updateURL('/termsConds')
          break
        }
        case Modals.SKINDIAG: {
          this.updateURL('/skinResults')
          break
        }
        case Modals.FACE_ANALYSIS: {
          this.updateURL('/faceAnalysis')
          break
        }
        case Modals.INFORMATION_PAGES: {
          this.updateURL('/information')
          break
        }
        default: {
          break
        }
      }
    },
    updateURL(path: string, upcs?: string) {
      window.history.pushState(
        { back: window.history.state?.current ?? '/chat', current: path },
        'Beauty Genius',
        path + (upcs ?? '')
      )
    },
    closeOverlay() {
      this.runningOverlay = Overlays.NONE
      this.updateURLBasedOnModal()
    },
    closeModal() {
      this.returnTo = [Modals.NONE]
      this.runningModal = Modals.NONE
      this.closeOverlay()
      if (this.page === Pages.AUTH) {
        window.history.pushState({ back: '', current: '/auth' }, 'Beauty Genius', '/auth')
      } else {
        window.history.pushState({ back: '', current: '/chat' }, 'Beauty Genius', '/chat')
      }
      this.vto = null
      window.localStorage.removeItem('modalHistory')
    }
  }
})
